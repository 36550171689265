import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  IDLE_STATUS,
  SUCCESS_STATUS,
  LOADING_STATUS,
  ALTERNATIVE_LOADING_STATUS,
  FAILURE_STATUS,
} from '../status';
import { AUTO_LOAD_CONTROL } from '../config-control';

import {
  fetchByNcm,
} from './regimeTributacaoIpiApi';

import i18n from '../../i18n';

const initialState = {
  status: IDLE_STATUS,
  error: null,
  response: {
    status: null,
    message: null,
    data: null,
  },
  config: { control: AUTO_LOAD_CONTROL },
  list: [],
};


const fetchByNcmAsync = createAsyncThunk(
  'regimeTributacaoIpi/fetchByNcmAsync',
  async (data) => {
    const response = await fetchByNcm(data);

    return {
      response: {
        status: response.status,
        data: response.data,
      },
    };
  }
);

const regimeTributacaoSliceIpi = createSlice({
  name: 'regimeTributacaoIpi',
  initialState,
  reducers: {
    changeStatusTo: (state, action) => {
      state.status = action.payload.status;
    },
    loading: (state) => {
      state.status = LOADING_STATUS;
    },
    success: (state) => {
      state.status = SUCCESS_STATUS;
    },
    failure: (state) => {
      state.status = FAILURE_STATUS;
    },
    resetStatus: (state) => {
      state.status = IDLE_STATUS;
    },
    setResponse: (state, action) => {
      state.response = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearResponse: (state) => {
      state.response = {
        status: null,
        message: null,
        data: null,
      };
    },
    resetList: (state) => {
      state.list = [];
    },
    setConfig: (state, action) => {
      state.config = action.payload;
    },
    changeControlTo: (state, action) => {
      state.config.control = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(fetchByNcmAsync.pending, (state) => {
      state.status = ALTERNATIVE_LOADING_STATUS;
    })
    .addCase(fetchByNcmAsync.fulfilled, (state, action) => {
      state.status = IDLE_STATUS;
      state.list = action?.payload?.response?.data?.regimeTributarios;
    })
    .addCase(fetchByNcmAsync.rejected, (state, action) => {
      state.status = FAILURE_STATUS;
      state.error = {
        ...action.error,
        message: i18n.t('com.muralis.qcx.erro.carregarListaRegimesTributacao', { erro: action.error.message }),
      };
    })
  },
});

const {
  changeStatusTo,
  loading,
  success,
  failure,
  resetStatus,
  setResponse,
  setResponseMessage,
  clearResponse,
  setError,
  resetList,
  setConfig,
  changeControlTo,
} = regimeTributacaoSliceIpi.actions;

const selectRegimesTributacaoIpi = (state) => state.regimeTributacaoIpi.list;

export {
  regimeTributacaoSliceIpi,
  changeStatusTo,
  loading,
  success,
  failure,
  resetStatus,
  setResponse,
  setResponseMessage,
  clearResponse,
  setError,
  fetchByNcmAsync,
  resetList,
  setConfig,
  changeControlTo,
  selectRegimesTributacaoIpi,
};

export default regimeTributacaoSliceIpi.reducer;
