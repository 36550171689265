import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, makeStyles } from '@material-ui/core';
import {
  fetchAllAsync,
  selectFundamentosLegaisRegimeTributacaoIi,
} from '../../features/fundamento-legal-regime-tributacao-ii/fundamentoLegalRegimeTributacaoIiSlice';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';
import QCXRenderConditionalComponent from '../../components/catalogo-produtos/QCXRenderConditionalComponent';
import QCXFormSubtitle from '../../components/form-title/QCXFormSubtitle';

const useStyles = makeStyles((theme) => ({
  gridFields: {
    paddingBottom: '16px',
  },
  subtitle: {
    paddingTop: '16px !important',
  },
  destaqueNcmFieldLeft: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: '16px',
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: '16px',
    },
  },
  // FIXME: Fake field
  hiddenFakeFields: {
    width: '0px',
    height: '0px',
    visibility: 'hidden',
    zIndex: -9999,
    position: 'absolute',
  },
}));


export default function QCXSelectFundamentoLegalRegimeTributacaoIiAutocomplete({
  label,
  name,
  initialValues,
  isConsult,
  isDuimp=false,
  ...restProps
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const list = useSelector(selectFundamentosLegaisRegimeTributacaoIi);

  const [defaultValue, setDefaultValue] = useState('');
  const [atributos, setAtributos] = useState([]);

  useEffect(() => {
    dispatch(fetchAllAsync(isDuimp));
  }, []);

  useEffect(() => {
    const value = list.find((item) => item?.id === _.get(initialValues, name));
    if (value) {
      setDefaultValue(value);
    } else {
      setDefaultValue({ id: null, description: '' });
    }

    if (value && value.listaAtributosMetadados && _.isArray(value.listaAtributosMetadados)) {
      setAtributos([...value.listaAtributosMetadados])
    } else {
      setAtributos([])
    }

  }, [list, initialValues]);

  const filterOptions = createFilterOptions({
    stringify: (item) => `${item?.code} - ${item?.description}`,
  });

  return (
    <>
      <QCXAutocompleteSelect
        label={label}
        id={name}
        name={name}
        options={list}
        filterOptions={filterOptions}
        defaultValue={defaultValue}
        getOptionLabel={(item) => (
          item?.code && item?.description
            ? `${item?.code} - ${item?.description}`
            : ''
        )}
        {...restProps}
      />
      
      {
        isDuimp && false && (
          <Grid container>
            <Grid item className={classes.subtitle} xs={12}>
              <QCXFormSubtitle title="ATRIBUTOS FUNDAMENTO LEGAL" />
            </Grid>

            <Grid container spacing={2} style={{ marginTop: '1rem' }}>
              {atributos != null && Array.isArray(atributos) && atributos.length ? (

                atributos.map((atributo) => (
                    <QCXRenderConditionalComponent
                      atributo={atributo} 
                      disabled={isConsult} 
                      isConsult={false}
                      codObjetivo='9'
                      basePath="ii"
                    />
                  )
                )
              ) : (
                <Grid item>Nenhum atributo de fundamento legal disponível para esse fundamento selecionado !!</Grid>
              )}
            </Grid>
          </Grid>
        )
      }
    </>

  );
}
