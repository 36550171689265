/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import { Grid, makeStyles } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import QCXFormTitle from '../../components/form-title/QCXFormTitle'
import QCXRenderConditionalComponent from '../../components/catalogo-produtos/QCXRenderConditionalComponent';

const useStyles = makeStyles((theme) => ({
  gridFields: {
    paddingBottom: '16px',
  },
  twin: {
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '16px',
    },
  },
  lastGridFields: {
    [theme.breakpoints.down('xs')]: {
      paddingBottom: '16px',
    },
  },
}));

export default function QCXAtributosTratamentoTributarioDuimp({ intl, modes, submitSucceeded, parentModel, isRepasse=false }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { values } = useFormState();

  const [atributosMetadados, setAtributosMetadados] = useState([]);
  const listTratamentosTributarios = useMemo(() => {
    if (!isRepasse) {
      return values?.ncm?.tratamentosTributarios;
    }

    let lista = [];
    
    if (values?.meta?.selected && Array.isArray(values?.meta?.selected)) {
      values.meta.selected.forEach((adicao) => {
        lista = [...lista, ...(adicao?.ncm?.tratamentosTributarios ?? [])];
      });
    }
    
    return lista;
    
  }, [values]);
 

  useEffect(() => {
    const atributosMetadadosArr = [];

    if (listTratamentosTributarios && Array.isArray(listTratamentosTributarios)) {
      listTratamentosTributarios.forEach(tratamento => {
        if (tratamento?.listaAtributosMetadados && Array.isArray(tratamento?.listaAtributosMetadados)) {
          tratamento?.listaAtributosMetadados.forEach(atributo => atributosMetadadosArr.push(atributo))
        }
      })
    }

    setAtributosMetadados([...atributosMetadadosArr])

  }, [listTratamentosTributarios]);


  const { isConsult, isSubNone, isSubConsult } = modes;


  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <QCXFormTitle title={"Atributos de Tratamento Tributario".toUpperCase()}/>
      </Grid>

      <Grid container>

        <Grid container spacing={2} style={{ marginTop: '1rem' }}>
          {atributosMetadados != null && Array.isArray(atributosMetadados) && atributosMetadados.length ? (

              atributosMetadados.map((atributo) => (
                <QCXRenderConditionalComponent
                  atributo={atributo} 
                  disabled={isConsult} 
                  isConsult={false}
                  codObjetivo='99'
                  basePath="atributosTratamentoTributario"
                />
              )
            )
          ) : (
            <Grid item>Nenhum atributo de fundamento legal disponível para esse fundamento selecionado !!</Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
}
