/* eslint-disable indent */
import _, { isEmpty, isFunction } from 'lodash';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { v4 as uuid, validate } from 'uuid';
import QCXDeclaracaoImportacaoWizardFinalForm from '../../../../components/declaracao-importacao/QCXDeclaracaoImportacaoWizardFinalForm';
import { changeControlTo as changeControlCatalogoProduto } from '../../../../features/catalogo-produtos/catalogoProdutosSlice';
import { changeControlTo as changeControlCfopTo } from '../../../../features/cfop-operacoes-fiscais/cfopOperacoesFiscaisSlice';
import { clienteActions } from '../../../../features/cliente/clienteSlice';
import { AUTO_LOAD_CONTROL, IDLE_CONTROL } from '../../../../features/config-control';
import {
  selectBackgroundMode,
  selectDeclaracaoImportacao,
  selectMode,
  selectStatus,
  selectSubBackgroundMode,
} from '../../../../features/declaracao-importacao/declaracaoImportacaoSelectors';
import {
  addToList,
  changeToBackgroundCreateMode,
  changeToBackgroundDeleteMode,
  changeToConsultMode,
  changeToCreateMode,
  changeToUpdateMode,
  failure,
  loading,
  preparingAction,
  resetBackgroundMode,
  resetModel,
  resetRelatedAdicaoModel,
  resetStatus,
  setModel,
  success,
  updateOnList,
} from '../../../../features/declaracao-importacao/declaracaoImportacaoSlice';
import {
  fetchByIdFromFaturaAsync,
  fetchCapaDiDuimpByIdFromFaturaAsync,
} from '../../../../features/declaracao-importacao/declaracaoImportacaoThunks';
import { faturaAPI } from '../../../../features/fatura/faturaAPI';
import { setErrorFeedback, setSuccessFeedback } from '../../../../features/feedback/feedbackSlice';
import { followUpAPI } from '../../../../features/follow-up/followUpAPI';
import { changeControlTo as changeControlMotivoImportacaoSemCoberturaCambialTo } from '../../../../features/motivo-importacao-sem-cobertura-cambial/motivoImportacaoSemCoberturaCambialSlice';
import { changeControlTo as changeControlNaladiNccaTo } from '../../../../features/naladi-ncca/naladiNccaSlice';
import { changeControlTo as changeControlNaladiShTo } from '../../../../features/naladi-sh/naladiShSlice';
import {
  changeControlTo as changeControlNcmTo,
  resetList as resetNcmList,
} from '../../../../features/ncm-subitem/ncmSubitemSlice';
import { changeControlTo as changeControlPaisTo } from '../../../../features/pais/paisSlice';
import { changeControlTo as changeControlRecintoAduaneiroTo } from '../../../../features/recinto-aduaneiro/recintoAduaneiroSlice';
import { changeControlTo as changeControlRegimeTributacaoTo } from '../../../../features/regime-tributacao/regimeTributacaoSlice';
import { changeControlTo as changeControlSetorLotacaoTo } from '../../../../features/setor-lotacao/setorLotacaoSlice';
import { changeControlTo as changeControlUnidadeMedidaTo } from '../../../../features/unidade-medida/unidadeDeMedidaSlice';
import QCXConfirmDialog from '../../../../shared-components/dialog/QCXConfirmDialog';
import { MODALIDADE_DESPACHO_NORMAL } from '../../../../shared-components/select-modalidade-despacho/modalidadeDespachoUtils';
import {
  TIPO_ACRESCIMO,
  TIPO_DEDUCAO,
} from '../../../../shared-components/select-tipo-acrescimo-deducao/tipoAcrescimoDeducaoUtils';
import { UTILIZACAO_TRANSPORTE_TOTAL } from '../../../../shared-components/select-utilizacao-transporte/utilizacaoTransporteUtils';
import QCXRegistrationFormPageTemplate from '../../../../templates/registration-form-page/QCXRegistrationFormPageTemplate';
import { MediaType } from '../../../../utils/api/api-utils';
import { TIPO_DI_DUIMP } from '../../../../utils/general/fatura/faturaUtils';
import {
  forceUnnormalizeNumeral,
  isValid,
  normalizeData,
  normalizeNumeral,
  unnormalizeData,
  unnormalizeNumeral,
} from '../../../../utils/general/general-utils';

import {
  convertLocalDateTimeStringToUTC,
  convertUTCStringToLocalDateTime,
} from '../../../../utils/general/dateTime-utils.ts';

import { isLiberadaStatus } from '../../../../utils/general/operations/operationUtils';
import {
  formatBrazilianNumericDecimal,
  formatBrazilianNumericDecimalWithoutSeparator,
} from '../../../../utils/hooks/form/field/formatters';
import useOperationConfirm from '../../../../utils/hooks/operation/confirm/useOperationConfirm';
import {
  isBackgroundCreateMode,
  isBackgroundDeleteMode,
  isConsultMode,
  isCreateMode,
  isFailureStatus,
  isIdleStatus,
  isLoadingStatus,
  isLockedMode,
  isNoneMode,
  isPreparingActionStatus,
  isSubBackgroundConsultMode,
  isSubBackgroundCreateMode,
  isSubBackgroundDeleteMode,
  isSubBackgroundUpdateMode,
  isUpdateMode,
} from '../../../../utils/store/store-utils';
import useConfigurePopUp from '../../../../ts/common/hooks/popUps/useConfigurePopUp';
import { limparAcrescimosAsync } from '../../../../features/fatura/faturaThunks';

export default function DeclaracaoImportacaoRegistrationPage({ authInfo = {} }) {
  const { t } = useTranslation();
  const params = useParams();

  const history = useHistory();

  const dispatch = useDispatch();

  const [isCreated, setIsCreated] = useState(false);

  const status = useSelector(selectStatus);
  const mode = useSelector(selectMode);
  const backgroundMode = useSelector(selectBackgroundMode);
  const subBackgroundMode = useSelector(selectSubBackgroundMode);
  const declaracaoImportacao = useSelector(selectDeclaracaoImportacao);

  const isPreparingAction = useMemo(() => isPreparingActionStatus(status), [status]);

  const isLoading = useMemo(() => isLoadingStatus(status), [status]);

  const isIdle = useMemo(() => isIdleStatus(status), [status]);

  const isFailure = useMemo(() => isFailureStatus(status), [status]);

  const isNone = useMemo(() => isNoneMode(mode), [mode]);

  const isCreate = useMemo(() => isCreateMode(mode), [mode]);

  const isConsult = useMemo(() => isConsultMode(mode), [mode]);

  const isUpdate = useMemo(() => isUpdateMode(mode), [mode]);

  const isLocked = useMemo(() => isLockedMode(mode), [mode]);

  const isBackgroundCreate = useMemo(() => isBackgroundCreateMode(backgroundMode), [backgroundMode]);

  const isBackgroundDelete = useMemo(() => isBackgroundDeleteMode(backgroundMode), [backgroundMode]);

  const isSubBackgroundCreate = useMemo(() => isSubBackgroundCreateMode(subBackgroundMode), [subBackgroundMode]);

  const isSubBackgroundConsult = useMemo(() => isSubBackgroundConsultMode(subBackgroundMode), [subBackgroundMode]);

  const isSubBackgroundUpdate = useMemo(() => isSubBackgroundUpdateMode(subBackgroundMode), [subBackgroundMode]);

  const isSubBackgroundDelete = useMemo(() => isSubBackgroundDeleteMode(subBackgroundMode), [subBackgroundMode]);

  const handleChangeToPreparingAction = useCallback(() => {
    dispatch(preparingAction());
  }, []);

  const handleChangeToCreate = useCallback(() => {
    dispatch(changeToCreateMode());
  }, []);

  const handleChangeToBackgroundCreate = useCallback(() => {
    dispatch(changeToBackgroundCreateMode());
  }, []);

  const handleChangeToBackgroundDelete = useCallback(() => {
    dispatch(changeToBackgroundDeleteMode());
  }, []);

  const handleChangeToConsult = useCallback(() => {
    dispatch(changeToConsultMode());
  }, []);

  const handleChangeToUpdate = useCallback((additional = {}) => {
    dispatch(changeToUpdateMode());

    if (!isEmpty(additional) && isFunction(additional?.callback)) {
      additional.callback();
    }
  }, []);

  useEffect(() => {
    dispatch(resetStatus());

    dispatch(clienteActions.changeControlTo(IDLE_CONTROL));
    dispatch(changeControlCatalogoProduto(AUTO_LOAD_CONTROL));
    dispatch(changeControlPaisTo(AUTO_LOAD_CONTROL));
    dispatch(changeControlRecintoAduaneiroTo(IDLE_CONTROL));
    dispatch(changeControlSetorLotacaoTo(IDLE_CONTROL));
    dispatch(changeControlNcmTo(AUTO_LOAD_CONTROL));
    dispatch(changeControlNaladiShTo(AUTO_LOAD_CONTROL));
    dispatch(changeControlNaladiNccaTo(AUTO_LOAD_CONTROL));
    dispatch(changeControlUnidadeMedidaTo(AUTO_LOAD_CONTROL));
    dispatch(changeControlCfopTo(AUTO_LOAD_CONTROL));
    dispatch(changeControlRegimeTributacaoTo(IDLE_CONTROL));
    dispatch(changeControlMotivoImportacaoSemCoberturaCambialTo(IDLE_CONTROL));

    return () => {
      dispatch(clienteActions.resetControl());
      dispatch(resetRelatedAdicaoModel());
    };
  }, []);

  const handleResetBackgroundMode = useCallback(() => {
    dispatch(resetBackgroundMode());
  }, []);

  const fetchById = useCallback((declaracaoImportacaoId) => {
    // dispatch(fetchByIdFromFaturaAsync(declaracaoImportacaoId));
    dispatch(fetchCapaDiDuimpByIdFromFaturaAsync(declaracaoImportacaoId));
  }, []);

  useEffect(() => {
    const handleFetchById = () => {
      if (!isEmpty(params?.id)) {
        fetchById(params?.id);

        handleChangeToConsult();
      }
    };

    handleFetchById();
  }, [params, handleChangeToConsult]);

  const handleRepassarMoedaMle = useCallback(({ form, values }) => {
    const adicoes = values?.atributosAdicionais?.adicoes || [];

    adicoes.forEach((adicao, index) => {
      if (values?.mle?.moeda) {
        form.change(`atributosAdicionais.adicoes[${index}].moeda`, values?.mle?.moeda);
      } else {
        form.change(`atributosAdicionais.adicoes[${index}].moeda`, null);
      }
    });
  }, []);

  const [openAskRepassarMoedasPopUp, askRepassarMoedasPopUpConfig] = useConfigurePopUp({
    primaryConfirmCallback: handleRepassarMoedaMle,
    initialOptions: { title: 'Moeda MLE Alterada', message: 'Deseja repassar a moeda para as adições?' },
  });

  const handleLimparAcrescimoDeducao = useCallback(() => {
    dispatch(limparAcrescimosAsync({ data: params?.id, finishCallback: handleBasicCancelUpdate }));
  }, [params]);

  const [openAskLimparAcrescimosDeducoes, askLimparAcrescimosDeducoesConfig] = useConfigurePopUp({
    primaryConfirmCallback: handleLimparAcrescimoDeducao,
    initialOptions: {
      title: 'Limpar Acréscimo/Dedução',
      message:
        'Atenção! Essa operação irá excluir os acréscimos e deduções das adições. Essa ação não poderá ser revertida. Deseja continuar?',
    },
  });

  const normalize = useCallback(
    (unnormalizedData) => {
      const {
        atributosAdicionais,
        importador,
        paisProcedencia,
        urfDespacho,
        urfEntrada,
        pesoLiquido,
        pesoBruto,
        data,
        rateiaPesoLiquidoItem,
        incotermCondicaoVenda,
        mle,
        despesa,
        frete,
        seguro,
        desconto,
        exportador,
        fabricante,
        paisOrigem,
        mercadorias,
        totaisAfrmm,
        despesasAcessorias,
        followUp,
        ...restUnnormalizedData
      } = unnormalizedData;

      const normalizedPagamentos = (atributosAdicionais?.pagamentos || [])?.map((pagamento) =>
        validate(pagamento?.id)
          ? {
              ...pagamento,
              id: null,
              valorReal: normalizeNumeral(pagamento?.valorReal),
              valorSiscomex: normalizeNumeral(pagamento?.valorSiscomex),
              codigoReceita: pagamento?.codigoReceita?.id ? pagamento?.codigoReceita : null,
              novoCodigoReceita: pagamento?.novoCodigoReceita?.id ? pagamento?.novoCodigoReceita : null,
            }
          : {
              ...pagamento,
              valorReal: normalizeNumeral(pagamento?.valorReal),
              valorSiscomex: normalizeNumeral(pagamento?.valorSiscomex),
              codigoReceita: pagamento?.codigoReceita?.id ? pagamento?.codigoReceita : null,
              novoCodigoReceita: pagamento?.novoCodigoReceita?.id ? pagamento?.novoCodigoReceita : null,
            }
      );

      const normalizedArmazens = (atributosAdicionais?.carga?.armazens || [])?.map((armazem) =>
        armazem?.id ? armazem?.value : armazem
      );

      const normalizedQuantidades = (atributosAdicionais?.carga?.quantidades || [])?.map((quantidade) =>
        validate(quantidade?.id)
          ? {
              ...quantidade,
              id: null,
              tipoEmbalagemNacional: quantidade?.tipoEmbalagemNacional?.id ? quantidade?.tipoEmbalagemNacional : null,
              quantidade: normalizeNumeral(quantidade?.quantidade),
            }
          : {
              ...quantidade,
              tipoEmbalagemNacional: quantidade?.tipoEmbalagemNacional?.id ? quantidade?.tipoEmbalagemNacional : null,
              quantidade: normalizeNumeral(quantidade?.quantidade),
            }
      );

      const normalizedDeclaracaoExportacoes = (atributosAdicionais?.carga?.declaracaoExportacoes || [])?.map((cde) =>
        validate(cde?.id)
          ? {
              ...cde,
              numero: cde.numero,
              inicio: cde.inicio,
              fim: cde.fim,
              id: null,
            }
          : {
              ...cde,
              numero: cde.numero,
              inicio: cde.inicio,
              fim: cde.fim,
            }
      );

      const normalizedVinculos = (atributosAdicionais?.vinculos || [])?.map((vinculo) =>
        validate(vinculo?.id)
          ? {
              ...vinculo,
              id: null,
            }
          : vinculo
      );

      const normalizedDocumentosInstrucoesDespacho = (atributosAdicionais?.documentosInstrucoesDespacho || [])?.map(
        (documentoInstrucaoDespacho) =>
          validate(documentoInstrucaoDespacho?.id)
            ? {
                ...documentoInstrucaoDespacho,
                id: null,
                documentoInstrucaoDespachoImportacao: documentoInstrucaoDespacho?.documentoInstrucaoDespachoImportacao
                  ?.id
                  ? documentoInstrucaoDespacho?.documentoInstrucaoDespachoImportacao
                  : null,
              }
            : documentoInstrucaoDespacho
      );

      const mapAcrecimoDeducao = (acrecimoDeducao) => {
        const isUUid = validate(acrecimoDeducao?.id);
        let deducaoValoracaoAduaneira =
          acrecimoDeducao?.acrescimoValoracaoAduaneira?.id && acrecimoDeducao?.tipo === TIPO_DEDUCAO
            ? acrecimoDeducao?.acrescimoValoracaoAduaneira
            : null;

        if (acrecimoDeducao?.deducaoValoracaoAduaneira) {
          deducaoValoracaoAduaneira = acrecimoDeducao?.deducaoValoracaoAduaneira;
        }

        if (isUUid) {
          const obj = {
            ...acrecimoDeducao,
            id: null,
            valorMoeda: normalizeNumeral(acrecimoDeducao?.valorMoeda),
            valorReal: normalizeNumeral(acrecimoDeducao?.valorReal),
            moeda: acrecimoDeducao?.moeda?.id ? acrecimoDeducao?.moeda : null,
            acrescimoValoracaoAduaneira:
              acrecimoDeducao?.acrescimoValoracaoAduaneira?.id && acrecimoDeducao?.tipo === TIPO_ACRESCIMO
                ? acrecimoDeducao?.acrescimoValoracaoAduaneira
                : null,
            deducaoValoracaoAduaneira,
          };
          return obj;
        }

        const obj = {
          ...acrecimoDeducao,
          valorMoeda: normalizeNumeral(acrecimoDeducao?.valorMoeda),
          valorReal: normalizeNumeral(acrecimoDeducao?.valorReal),
          moeda: acrecimoDeducao?.moeda?.id ? acrecimoDeducao?.moeda : null,
          acrescimoValoracaoAduaneira:
            acrecimoDeducao?.acrescimoValoracaoAduaneira?.id && acrecimoDeducao?.tipo === TIPO_ACRESCIMO
              ? acrecimoDeducao?.acrescimoValoracaoAduaneira
              : null,
          deducaoValoracaoAduaneira,
        };

        return obj;
      };
      const normalizedAcrescimosDeducoes = (atributosAdicionais?.acrescimosDeducoes || [])?.map(mapAcrecimoDeducao);

      const normalizedMercadorias = mercadorias || [];
      const normalizedAdicoes =
        atributosAdicionais?.adicoes?.map((a) => ({ ...a, valorMleMoeda: normalizeNumeral(a.valorMleMoeda) })) || [];

      const normalizedAfmm = {
        ...totaisAfrmm,
        valorDolar: normalizeNumeral(totaisAfrmm?.valorDolar),
        valorReal: normalizeNumeral(totaisAfrmm?.valorReal),
        valorMoeda: normalizeNumeral(totaisAfrmm?.valorMoeda),
      };

      const normalizedDespesasAcessorias = {
        ...despesasAcessorias,
        valorReal: normalizeNumeral(despesasAcessorias?.valorReal),
        valorMoeda: normalizeNumeral(despesasAcessorias?.valorMoeda),
      };

      const normalizedData = {
        ..._.omit(restUnnormalizedData, 'ignorableFields'),
        data: normalizeData(data),
        followUp: {
          ...followUp,
        },
        importador: importador?.id ? importador : null,
        urfDespacho: urfDespacho?.id ? urfDespacho : null,
        pesoLiquido: normalizeNumeral(pesoLiquido),
        pesoBruto: normalizeNumeral(pesoBruto),
        rateiaPesoLiquidoItem,
        paisProcedencia: paisProcedencia?.id ? paisProcedencia : null,
        urfEntrada: urfEntrada?.id ? urfEntrada : null,
        incotermCondicaoVenda: incotermCondicaoVenda?.id ? incotermCondicaoVenda : null,
        atributosAdicionais: {
          ...atributosAdicionais,
          tipo: TIPO_DI_DUIMP,
          processo: atributosAdicionais?.processo,
          fundap: atributosAdicionais?.fundap,
          informacoesComplementares: atributosAdicionais?.informacoesComplementares,
          informacoesComplementaresCompleta: atributosAdicionais?.informacoesComplementaresCompleta,
          tempoPermanencia: atributosAdicionais?.tempoPermanencia,
          vidaUtil: atributosAdicionais?.vidaUtil,
          caracterizacaoOperacao: atributosAdicionais?.caracterizacaoOperacao,
          modalidadeDespacho: atributosAdicionais?.modalidadeDespacho,
          vinculacaoCompradorVendedor: atributosAdicionais?.vinculacaoCompradorVendedor,
          tipoDeclaracao: atributosAdicionais?.tipoDeclaracao?.id ? atributosAdicionais?.tipoDeclaracao : null,
          acrescimosDeducoes: normalizedAcrescimosDeducoes,
          vinculos: normalizedVinculos,
          documentosInstrucoesDespacho: normalizedDocumentosInstrucoesDespacho,
          pagamentos: normalizedPagamentos,
          dadosBancarios: {
            ...atributosAdicionais?.dadosBancarios,
            banco: atributosAdicionais?.dadosBancarios?.banco?.id ? atributosAdicionais?.dadosBancarios?.banco : null,
          },
          transporte: {
            ...atributosAdicionais?.transporte,
            dataEmbarque: convertLocalDateTimeStringToUTC(atributosAdicionais?.transporte?.dataEmbarque),
            dataPostagem: normalizeData(atributosAdicionais?.transporte?.dataPostagem),
            viaTransporte: atributosAdicionais?.transporte?.viaTransporte?.id
              ? atributosAdicionais?.transporte?.viaTransporte
              : null,
            transportadorInternacional:
              atributosAdicionais?.transporte?.transportadorInternacional?.transportador?.id ||
              atributosAdicionais?.transporte?.transportadorInternacional?.pais?.id
                ? {
                    ...atributosAdicionais?.transporte?.transportadorInternacional,
                    transportador: atributosAdicionais?.transporte?.transportadorInternacional?.transportador?.id
                      ? atributosAdicionais?.transporte?.transportadorInternacional?.transportador
                      : null,
                    pais: atributosAdicionais?.transporte?.transportadorInternacional?.pais?.id
                      ? atributosAdicionais?.transporte?.transportadorInternacional?.pais
                      : null,
                  }
                : null,
            portoAeroporto: atributosAdicionais?.transporte?.portoAeroporto?.id
              ? atributosAdicionais?.transporte?.portoAeroporto
              : null,
          },
          carga: {
            ...atributosAdicionais?.carga,
            armazens: normalizedArmazens,
            agenteTransportador: atributosAdicionais?.carga?.agenteTransportador?.id
              ? atributosAdicionais?.carga?.agenteTransportador
              : null,
            setorLotacao: atributosAdicionais?.carga?.setorLotacao?.id
              ? atributosAdicionais?.carga?.setorLotacao
              : null,
            recintoAduaneiro: atributosAdicionais?.carga?.recintoAduaneiro?.id
              ? atributosAdicionais?.carga?.recintoAduaneiro
              : null,
            quantidades: normalizedQuantidades,
            declaracaoExportacoes: normalizedDeclaracaoExportacoes,
          },
          adquirente: atributosAdicionais?.adquirente?.id ? atributosAdicionais?.adquirente : null,
          adicoes: normalizedAdicoes,
          documentosAnexacaoValor: _.isArray(atributosAdicionais?.documentosAnexacaoValor)
            ? atributosAdicionais?.documentosAnexacaoValor?.map((documento) => {
                if (validate(documento?.id)) {
                  return {
                    ...documento,
                    id: null,
                  };
                }
                return documento;
              })
            : [],
        },
        mle: {
          ...mle,
          moeda: mle?.moeda?.id ? mle?.moeda : null,
          valorMoeda: normalizeNumeral(mle?.valorMoeda),
          valorReal: normalizeNumeral(mle?.valorReal),
        },
        desconto: {
          ...desconto,
          moeda: desconto?.moeda?.id ? desconto?.moeda : null,
          valorMoeda: normalizeNumeral(desconto?.valorMoeda),
          valorReal: normalizeNumeral(desconto?.valorReal),
        },
        despesa: {
          ...despesa,
          moeda: despesa?.moeda?.id ? despesa?.moeda : null,
          valorMoeda: normalizeNumeral(despesa?.valorMoeda),
          valorReal: normalizeNumeral(despesa?.valorReal),
        },
        frete: {
          ...frete,
          moeda: frete?.moeda?.id ? frete?.moeda : null,
          valorMoedaCollect: normalizeNumeral(frete?.valorMoedaCollect),
          valorRealCollect: normalizeNumeral(frete?.valorRealCollect),
          valorMoedaPrepaid: normalizeNumeral(frete?.valorMoedaPrepaid),
          valorRealPrepaid: normalizeNumeral(frete?.valorRealPrepaid),
          valorFreteNacionalMoeda: normalizeNumeral(frete?.valorFreteNacionalMoeda),
          valorFreteNacionalReal: normalizeNumeral(frete?.valorFreteNacionalReal),
        },
        seguro: {
          ...seguro,
          moeda: seguro?.moeda?.id ? seguro?.moeda : null,
          valorMoeda: normalizeNumeral(seguro?.valorMoeda),
          valorReal: normalizeNumeral(seguro?.valorReal),
          valorPercentual: normalizeNumeral(seguro?.valorPercentual),
        },
        totaisAfrmm: normalizedAfmm,
        despesasAcessorias: normalizedDespesasAcessorias,
        exportador: exportador?.id ? exportador : null,
        fabricante: fabricante?.id ? fabricante : null,
        paisOrigem: paisOrigem?.id ? paisOrigem : null,
        mercadorias: normalizedMercadorias,
      };

      return normalizedData;
    },
    [validate, normalizeData, normalizeNumeral]
  );

  const unnormalize = useCallback(
    (normalizedData) => {
      const {
        pesoLiquido,
        pesoBruto,
        data,
        atributosAdicionais,
        mle,
        desconto,
        despesa,
        frete,
        seguro,
        mercadorias,
        lis,
        totaisAfrmm,
        followUp,
        despesasAcessorias,
      } = normalizedData;

      const unnormalizedAdicoes = atributosAdicionais?.adicoes || [];

      const normalizedPagamentos = (atributosAdicionais?.pagamentos || [])?.map((pagamento) =>
        validate(pagamento?.id)
          ? {
              ...pagamento,
              id: null,
              valorReal: isValid(pagamento?.valorReal)
                ? unnormalizeNumeral(pagamento?.valorReal, formatBrazilianNumericDecimal(2))
                : undefined,
              codigoReceita: pagamento?.codigoReceita?.id ? pagamento?.codigoReceita : null,
              novoCodigoReceita: pagamento?.novoCodigoReceita?.id ? pagamento?.novoCodigoReceita : null,
            }
          : {
              ...pagamento,
              valorReal: isValid(pagamento?.valorReal)
                ? unnormalizeNumeral(pagamento?.valorReal, formatBrazilianNumericDecimal(2))
                : undefined,
              codigoReceita: pagamento?.codigoReceita?.id ? pagamento?.codigoReceita : null,
              novoCodigoReceita: pagamento?.novoCodigoReceita?.id ? pagamento?.novoCodigoReceita : null,
            }
      );
      normalizedPagamentos.sort((a, b) => {
        if (a?.codigoReceita?.code > b?.codigoReceita?.code) {
          return 1;
        }
        if (a?.codigoReceita?.code < b?.codigoReceita?.code) {
          return -1;
        }
        return 0;
      });
      const normalizedAcrescimosAduaneiros = atributosAdicionais?.acrescimosDeducoes || [];

      const normalizedMercadorias = mercadorias || [];

      const normalizedLis = lis || [];

      const normalizedQuantidades = atributosAdicionais?.carga?.quantidades || [];

      const normalizedDeclaracaoExportacoes = atributosAdicionais?.carga?.declaracaoExportacoes || [];

      const unnormalizedAfrmm = {
        ...totaisAfrmm,
        valorDolar: unnormalizeNumeral(totaisAfrmm?.valorDolar, formatBrazilianNumericDecimal(2)),
        valorReal: unnormalizeNumeral(totaisAfrmm?.valorReal, formatBrazilianNumericDecimal(2)),
        valorMoeda: unnormalizeNumeral(totaisAfrmm?.valorMoeda, formatBrazilianNumericDecimal(2)),
      };

      const unnormalizedDespesasAcessorias = {
        ...despesasAcessorias,
        valorReal: unnormalizeNumeral(despesasAcessorias?.valorReal, formatBrazilianNumericDecimal(2)),
        valorMoeda: unnormalizeNumeral(despesasAcessorias?.valorMoeda, formatBrazilianNumericDecimal(2)),
      };

      return {
        ...normalizedData,
        pesoLiquido: isValid(pesoLiquido)
          ? forceUnnormalizeNumeral(pesoLiquido, formatBrazilianNumericDecimal(5))
          : undefined,
        pesoBruto: isValid(pesoBruto)
          ? forceUnnormalizeNumeral(pesoBruto, formatBrazilianNumericDecimal(5))
          : undefined,
        data: unnormalizeData(data),
        atributosAdicionais: {
          ...atributosAdicionais,
          acrescimosDeducoes: normalizedAcrescimosAduaneiros.map((acrescimoAduaneiro) => ({
            ...acrescimoAduaneiro,
            valorMoeda: isValid(acrescimoAduaneiro?.valorMoeda)
              ? unnormalizeNumeral(acrescimoAduaneiro?.valorMoeda, formatBrazilianNumericDecimal(2))
              : undefined,
            valorReal: isValid(acrescimoAduaneiro?.valorReal)
              ? unnormalizeNumeral(acrescimoAduaneiro?.valorReal, formatBrazilianNumericDecimal(2))
              : undefined,
            acrescimoValoracaoAduaneira: !acrescimoAduaneiro?.acrescimoValoracaoAduaneira
              ? acrescimoAduaneiro?.deducaoValoracaoAduaneira
              : acrescimoAduaneiro?.acrescimoValoracaoAduaneira,
          })),
          adicoes: unnormalizedAdicoes,
          pagamentos: normalizedPagamentos.map((pagamento) => ({
            ...pagamento,
            valor: isValid(pagamento?.valor)
              ? unnormalizeNumeral(pagamento?.valor, formatBrazilianNumericDecimal(2))
              : undefined,
            valorSiscomex: isValid(pagamento?.valorSiscomex)
              ? unnormalizeNumeral(pagamento?.valorSiscomex, formatBrazilianNumericDecimal(2))
              : undefined,
          })),
          transporte: {
            ...atributosAdicionais?.transporte,
            dataEmbarque: unnormalizeData(atributosAdicionais?.transporte?.dataEmbarque),
            dataPostagem: unnormalizeData(atributosAdicionais?.transporte?.dataPostagem),
          },
          carga: {
            ...atributosAdicionais?.carga,
            armazens: (atributosAdicionais?.carga?.armazens ?? []).map((armazem) => ({ id: uuid(), value: armazem })),
            quantidades: normalizedQuantidades.map((quantidade) => ({
              ...quantidade,
              quantidade: isValid(quantidade?.quantidade)
                ? unnormalizeNumeral(quantidade?.quantidade, formatBrazilianNumericDecimalWithoutSeparator(0))
                : undefined,
            })),
            declaracaoExportacoes: normalizedDeclaracaoExportacoes.map((cde) => ({
              ...cde,
              number: cde.numero,
              start: cde.inicio,
              end: cde.fim,
            })),
          },
        },
        mle: {
          ...mle,
          valorMoeda: isValid(mle?.valorMoeda)
            ? unnormalizeNumeral(mle?.valorMoeda, formatBrazilianNumericDecimal(2))
            : undefined,
          valorReal: isValid(mle?.valorReal)
            ? unnormalizeNumeral(mle?.valorReal, formatBrazilianNumericDecimal(2))
            : undefined,
        },
        desconto: {
          ...desconto,
          moeda: desconto?.moeda?.id ? desconto?.moeda : undefined,
          valorMoeda: isValid(desconto?.valorMoeda)
            ? unnormalizeNumeral(desconto?.valorMoeda, formatBrazilianNumericDecimal(2))
            : undefined,
          valorReal: isValid(desconto?.valorReal)
            ? unnormalizeNumeral(desconto?.valorReal, formatBrazilianNumericDecimal(2))
            : undefined,
        },
        despesa: {
          ...despesa,
          valorMoeda: isValid(despesa?.valorMoeda)
            ? unnormalizeNumeral(despesa?.valorMoeda, formatBrazilianNumericDecimal(2))
            : undefined,
          valorReal: isValid(despesa?.valorReal)
            ? unnormalizeNumeral(despesa?.valorReal, formatBrazilianNumericDecimal(2))
            : undefined,
        },
        frete: {
          ...frete,
          moeda: frete?.moeda?.id ? frete?.moeda : undefined,
          valorMoedaPrepaid: isValid(frete?.valorMoedaPrepaid)
            ? unnormalizeNumeral(frete?.valorMoedaPrepaid, formatBrazilianNumericDecimal(2))
            : undefined,
          valorRealPrepaid: isValid(frete?.valorRealPrepaid)
            ? unnormalizeNumeral(frete?.valorRealPrepaid, formatBrazilianNumericDecimal(2))
            : undefined,
          valorFreteNacionalMoeda: isValid(frete?.valorFreteNacionalMoeda)
            ? unnormalizeNumeral(frete?.valorFreteNacionalMoeda, formatBrazilianNumericDecimal(2))
            : undefined,
          valorFreteNacionalReal: isValid(frete?.valorFreteNacionalReal)
            ? unnormalizeNumeral(frete?.valorFreteNacionalReal, formatBrazilianNumericDecimal(2))
            : undefined,
          valorMoedaCollect: isValid(frete?.valorMoedaCollect)
            ? unnormalizeNumeral(frete?.valorMoedaCollect, formatBrazilianNumericDecimal(2))
            : undefined,
          valorRealCollect: isValid(frete?.valorRealCollect)
            ? unnormalizeNumeral(frete?.valorRealCollect, formatBrazilianNumericDecimal(2))
            : undefined,
        },
        seguro: {
          ...seguro,
          moeda: seguro?.moeda?.id ? seguro?.moeda : undefined,
          valorMoeda: isValid(seguro?.valorMoeda)
            ? unnormalizeNumeral(seguro?.valorMoeda, formatBrazilianNumericDecimal(2))
            : undefined,
          valorReal: isValid(seguro?.valorReal)
            ? unnormalizeNumeral(seguro?.valorReal, formatBrazilianNumericDecimal(2))
            : undefined,
          valorPercentual: isValid(seguro?.valorPercentual)
            ? unnormalizeNumeral(seguro?.valorPercentual, formatBrazilianNumericDecimal(7))
            : undefined,
          parametroCalcSeguro:
            normalizedData?.seguro?.parametroCalcSeguro ?? normalizedData?.importador?.pessoa?.parametroCalcSeguro,
        },
        totaisAfrmm: unnormalizedAfrmm,
        despesasAcessorias: unnormalizedDespesasAcessorias,
        mercadorias: normalizedMercadorias,
        lis: normalizedLis,
      };
    },
    [isValid, unnormalizeData, unnormalizeNumeral, formatBrazilianNumericDecimal]
  );

  const handleDispatchSetModel = useCallback(
    (data) => {
      const normalizedData = normalize(data);

      dispatch(setModel(normalizedData));
    },
    [normalize]
  );

  const createByStep = async (data, step, next) => {
    const isFirstStep = step === 0;
    const isLastStep = step === 8;

    const executeDebounced = debounce(async () => {
      try {
        if (isFirstStep && !isCreated) {
          const response = await faturaAPI.register(data, [{ name: 'STEP', value: step + 1 }]);

          if (response?.status === 201) {
            next();
            dispatch(resetModel());
            dispatch(success());

            const created = response?.data;

            handleDispatchSetModel(created);
            dispatch(addToList({ data: created }));
            dispatch(fetchByIdFromFaturaAsync(created?.id));

            setIsCreated(true);
          }
        } else if (isLastStep) {
          const response = await faturaAPI.save(data, [{ name: 'STEP', value: step + 1 }]);

          if (response?.status === 200) {
            dispatch(resetModel());

            const handleResultWithDebounce = debounce(() => {
              history.push(
                t('com.muralis.qcx.url.importacaoDeclaracoesQuery', { processo: data?.atributosAdicionais?.processo })
              );

              dispatch(success());

              const saved = response?.data;

              dispatch(
                setSuccessFeedback({
                  status: response.status,
                  data: saved,
                  message: t('com.muralis.qcx.mensagem.DIDUIMPRegistrada', {
                    processo: data?.atributosAdicionais?.processo,
                  }),
                })
              );

              dispatch(updateOnList({ data: saved }));
            }, 500);

            handleResultWithDebounce();
          }
        } else {
          const response = await faturaAPI.save(data, [{ name: 'STEP', value: step + 1 }]);

          if (response?.status === 200) {
            if (isCreate) {
              next();
            } else {
              handleChangeToConsult();
            }

            const saved = response?.data;

            dispatch(success());
            dispatch(setModel(saved));
            dispatch(updateOnList({ data: saved }));
            dispatch(fetchByIdFromFaturaAsync(saved?.id));
          }
        }
      } catch (error) {
        dispatch(failure());
        let errorMessage = t('com.muralis.qcx.erro.erroSalvarDadosDIDUIMP');
        if (error?.response && error?.response?.data) {
          errorMessage = t('com.muralis.qcx.erro.erroSalvarDadosDIDUIMPEspecifico', {
            erro: error?.response?.data?.message,
          });
        }
        dispatch(
          setErrorFeedback({
            message: errorMessage,
          })
        );
      }
    }, 500);

    dispatch(loading());
    executeDebounced();
  };

  const parseToFormData = useCallback((data) => {
    const formData = new FormData();

    const json = JSON.stringify(data);
    const blob = new Blob([json], {
      type: MediaType.APPLICATION_JSON,
    });

    formData.append('data', blob);

    // É necessario passar o campo metadata vazio, pois o back tem esse campo como obrigatório
    const meta = { metadata: [] };
    const jsonMeta = JSON.stringify(meta);
    const blobMeta = new Blob([jsonMeta], {
      type: MediaType.APPLICATION_JSON,
    });

    formData.append('metadata', blobMeta);

    return formData;
  }, []);

  const update = async (data, step) => {
    const executeDebounced = debounce(async () => {
      try {
        const isCapaDiDuimp = step + 1 <= 7;
        const response = await faturaAPI.save(data, [
          { name: 'STEP', value: step + 1 },
          { name: 'isCapaDiDuimp', value: isCapaDiDuimp },
        ]);

        const formData = parseToFormData(data.followUp);
        const responseFollowUp = await followUpAPI.save(formData);

        if (response?.status === 200 && responseFollowUp.status === 200) {
          const handleResultWithDebounce = debounce(async () => {
            handleChangeToConsult();
            dispatch(success());

            const saved = response?.data;
            const savedFollowUp = responseFollowUp?.data;

            dispatch(
              setSuccessFeedback({
                message: t('com.muralis.qcx.mensagem.DIDUIMPSalva', { processo: data?.atributosAdicionais?.processo }),
              })
            );

            dispatch(setModel({ ...saved, followUp: savedFollowUp ?? saved?.followUp }));
            dispatch(updateOnList({ data: saved }));
            dispatch(fetchByIdFromFaturaAsync(saved?.id));
          }, 500);

          handleResultWithDebounce();
        }
      } catch ({ response }) {
        dispatch(failure());
        dispatch(
          setErrorFeedback({
            message: t('com.muralis.qcx.erro.erroSalvarDadosDIDUIMPProcesso', {
              processo: data?.atributosAdicionais?.processo,
              erro: response?.data?.message,
            }),
          })
        );
      }
    }, 500);

    dispatch(loading());
    executeDebounced();
  };

  const handleSubmit = async (data, step, next) => {
    const normalizedData = normalize(data);
    if (isUpdate && !isBackgroundCreate) {
      await update(normalizedData, step);
      handleChangeToConsult();
      dispatch(resetNcmList());
      return;
    }

    if (isCreate || isBackgroundCreate) {
      await createByStep(normalizedData, step, next);
      dispatch(resetNcmList());
      return;
    }

    next();
  };

  const handleAlternativeSave = async (event, step, next) => {
    if (event && !isEmpty(event)) {
      event.stopPropagation();
    }

    const normalizedData = normalize(declaracaoImportacao);

    if (isUpdate) {
      await update(normalizedData, step);
    } else {
      await createByStep(normalizedData, step, next);
    }
  };

  const handleCustomSaveBy = async (options) => {
    if (!isFunction(options?.composeWith)) {
      throw new Error('Required Function Error: "composeWith" is not a function.');
    }

    const unnormalizedData = options?.composeWith(declaracaoImportacao);

    const normalizedData = normalize(unnormalizedData);

    const response = await faturaAPI.save(normalizedData, [
      {
        name: 'STEP',
        value: options?.step,
      },
    ]);

    return response;
  };

  const refreshSelectedModel = useCallback(() => {
    if (!isEmpty(declaracaoImportacao) && declaracaoImportacao?.id) {
      fetchById(declaracaoImportacao?.id);
    }
  }, [declaracaoImportacao, isUpdate, fetchById, handleChangeToConsult]);

  const initialValuesForRegistration = useMemo(() => {
    let conta = declaracaoImportacao?.followUp?.importador?.contaBancariaImpostosFederais?.contaCorrente;

    if (declaracaoImportacao?.followUp?.importador?.contaBancariaImpostosFederais?.removerDigitoXml) {
      conta = conta?.slice(0, -1);
    }

    return {
      followUp: {
        ...declaracaoImportacao?.followUp,
        id: declaracaoImportacao?.followUp?.id,
      },
      importador: {
        id: declaracaoImportacao?.followUp?.importador?.id,
      },
      atributosAdicionais: {
        processo: declaracaoImportacao?.atributosAdicionais?.processo,
        tipoDeclaracao: {
          id: declaracaoImportacao?.followUp?.servico?.tipoDeclaracao?.id,
        },
        modalidadeDespacho: MODALIDADE_DESPACHO_NORMAL,
        documentosInstrucoesDespacho: declaracaoImportacao?.followUp?.documentos?.map((documento) => ({
          id: uuid(),
          documentoInstrucaoDespachoImportacao: documento?.documentoInstrucaoDespachoImportacao,
          identificacao: documento?.numero,
        })),
        transporte: {
          viaTransporte: {
            id: declaracaoImportacao?.followUp?.servico?.viaTransporte?.id,
          },
          utilizacao: UTILIZACAO_TRANSPORTE_TOTAL,
          identificacaoCrt: declaracaoImportacao?.followUp?.numeroConhecimentoHouse,
          presencaDeCarga: declaracaoImportacao?.followUp?.numeroPresencaCarga,
        },
        dadosBancarios: {
          banco: {
            id: declaracaoImportacao?.followUp?.importador?.contaBancariaImpostosFederais?.banco?.id,
          },
          agencia: declaracaoImportacao?.followUp?.importador?.contaBancariaImpostosFederais?.agencia,
          conta,
          removerDigitoXml: declaracaoImportacao?.followUp?.importador?.contaBancariaImpostosFederais?.removerDigitoXml,
          removerDigitoAgenciaXml:
            declaracaoImportacao?.followUp?.importador?.contaBancariaImpostosFederais?.removerDigitoAgenciaXml,
        },
      },
      mle: { mesmaMoedaCondicaoVenda: true },
      seguro: {
        valorPercentual: declaracaoImportacao?.followUp?.importador?.pessoa?.valorPercentualSeguro,
        moeda: declaracaoImportacao?.followUp?.importador?.pessoa?.moeda,
        calculaSobreMleFrete:
          declaracaoImportacao?.followUp?.importador?.pessoa?.tipoCalculoSeguro === 'CALCULA_SOBRE_MLE_FRETE',
      },
    };
  }, [declaracaoImportacao]);

  const model = useMemo(
    () => (isCreated || !isCreate ? unnormalize(declaracaoImportacao) : initialValuesForRegistration),
    [isCreate, isCreated, unnormalize, declaracaoImportacao, initialValuesForRegistration]
  );

  const isLiberada = useMemo(() => isLiberadaStatus(model?.atributosAdicionais?.liberada), [model]);

  const actionName = useMemo(() => {
    if (isCreate || isBackgroundCreate || isNone) return t('com.muralis.qcx.acoes.novo');
    if (isConsult) return t('com.muralis.qcx.acoes.visualizar');
    return t('com.muralis.qcx.acoes.alterar');
  }, [isNone, isCreate, isConsult, isBackgroundCreate]);

  const breadcrumbs = useMemo(
    () => [
      {
        link: {
          to: '/',
          name: t('com.muralis.qcx.inicio'),
        },
      },
      {
        link: {
          to: t('com.muralis.qcx.url.moduloImportacao'),
          name: t('com.muralis.qcx.importacao.label'),
        },
      },
      {
        link: {
          to: t('com.muralis.qcx.url.importacaoDeclaracoesImportacao'),
          name: t('com.muralis.qcx.DIDUIMP.declaracoesImportacaoDIDUIMP'),
        },
      },
      {
        text: {
          name: isCreate ? t('com.muralis.qcx.registroManual') : t('com.muralis.qcx.registro'),
        },
      },
      {
        text: {
          name: actionName,
        },
      },
    ],
    [isCreate, actionName]
  );

  const pageTitle = useMemo(
    () =>
      isNone || isCreate || isBackgroundCreate
        ? t('com.muralis.qcx.DIDUIMP.novoRegistroManualDIDUIMNumero', {
            processo: model?.atributosAdicionais?.processo || '-',
          })
        : t('com.muralis.qcx.DIDUIMP.declaracaoImportacaoNumero', {
            processo: model?.atributosAdicionais?.processo || '-',
          }),
    [isNone, isCreate, isBackgroundCreate, model]
  );

  const handleBasicCancelUpdate = useCallback(
    (additional = {}) => {
      const currentId = isBackgroundCreate ? declaracaoImportacao?.id : params?.id;

      if (currentId) {
        fetchById(currentId);
      }

      handleChangeToConsult();

      if (!isEmpty(additional) && isFunction(additional?.callback)) {
        additional.callback();
      }
    },
    [params, declaracaoImportacao, isBackgroundCreate, fetchById, handleChangeToConsult]
  );

  const operationOfCancelUpdateOptions = useMemo(
    () => ({
      title: t('com.muralis.qcx.aviso').toUpperCase(),
      message: t('com.muralis.qcx.cancelarAlteracoesPerdidas'),
      endMessage: t('com.muralis.qcx.mensagem.certezaDesejaCancelar'),
    }),
    []
  );

  const [handleCancelUpdate, operationOfCancelUpdate] = useOperationConfirm(
    handleBasicCancelUpdate,
    operationOfCancelUpdateOptions,
    [handleBasicCancelUpdate, operationOfCancelUpdateOptions]
  );

  return (
    <QCXRegistrationFormPageTemplate
      pageTitle={pageTitle}
      breadcrumbs={breadcrumbs}
      isIdle={isIdle}
      isLoading={isLoading}
      isFailure={isFailure}
      isCreate={isCreate}
      isConsult={isConsult}
      isUpdate={isUpdate}
      isLocked={isLocked}
      isPreparingAction={isPreparingAction}
      isBackgroundCreate={isBackgroundCreate}
      isBackgroundDelete={isBackgroundDelete}
      isSubBackgroundCreate={isSubBackgroundCreate}
      isSubBackgroundConsult={isSubBackgroundConsult}
      isSubBackgroundUpdate={isSubBackgroundUpdate}
      isSubBackgroundDelete={isSubBackgroundDelete}
      handleAlternativeSave={handleAlternativeSave}
      handleChangeToPreparingAction={handleChangeToPreparingAction}
      handleChangeToCreate={handleChangeToCreate}
      handleChangeToBackgroundCreate={handleChangeToBackgroundCreate}
      handleChangeToBackgroundDelete={handleChangeToBackgroundDelete}
      handleChangeToConsult={handleChangeToConsult}
      handleChangeToUpdate={handleChangeToUpdate}
      handleCancelUpdate={handleCancelUpdate}
      handleResetBackgroundMode={handleResetBackgroundMode}
      showSubtitle={false}
      authInfo={authInfo}
      disableUpdate={isLiberada}
    >
      {(formProps) => (
        <QCXDeclaracaoImportacaoWizardFinalForm
          model={model}
          handleChangeModel={handleDispatchSetModel}
          handleSubmit={handleSubmit}
          handleCustomSaveBy={handleCustomSaveBy}
          refreshSelectedModel={refreshSelectedModel}
          authInfo={authInfo}
          requiredRoles={['importacao-di-duimp']}
          openAskRepassarMoedasPopUp={openAskRepassarMoedasPopUp}
          openAskLimparAcrescimosDeducoes={openAskLimparAcrescimosDeducoes}
          {...formProps}
        >
          <QCXConfirmDialog
            open={operationOfCancelUpdate?.active}
            title={operationOfCancelUpdate?.title}
            content={operationOfCancelUpdate?.message}
            endContent={operationOfCancelUpdate?.endMessage}
            onConfirm={operationOfCancelUpdate.confirm}
            onClose={operationOfCancelUpdate?.reset}
            buttonGroupOptions={{
              confirm: {
                description: t('com.muralis.qcx.expressao.sim'),
              },
              cancel: {
                description: t('com.muralis.qcx.expressao.nao'),
              },
            }}
          />
          <QCXConfirmDialog
            open={askRepassarMoedasPopUpConfig?.isPopUpOpen}
            title={askRepassarMoedasPopUpConfig?.title}
            content={askRepassarMoedasPopUpConfig?.message}
            endContent={askRepassarMoedasPopUpConfig?.endMessage}
            onConfirm={askRepassarMoedasPopUpConfig.confirmPrimaryAction}
            onClose={askRepassarMoedasPopUpConfig?.reset}
            buttonGroupOptions={{
              confirm: {
                description: t('com.muralis.qcx.expressao.sim'),
              },
              cancel: {
                description: t('com.muralis.qcx.expressao.nao'),
              },
            }}
          />
          <QCXConfirmDialog
            open={askLimparAcrescimosDeducoesConfig?.isPopUpOpen}
            title={askLimparAcrescimosDeducoesConfig?.title}
            content={askLimparAcrescimosDeducoesConfig?.message}
            endContent={askLimparAcrescimosDeducoesConfig?.endMessage}
            onConfirm={askLimparAcrescimosDeducoesConfig.confirmPrimaryAction}
            onClose={askLimparAcrescimosDeducoesConfig?.reset}
            buttonGroupOptions={{
              confirm: {
                description: t('com.muralis.qcx.expressao.sim'),
              },
              cancel: {
                description: t('com.muralis.qcx.expressao.nao'),
              },
            }}
          />
        </QCXDeclaracaoImportacaoWizardFinalForm>
      )}
    </QCXRegistrationFormPageTemplate>
  );
}
