import { createAsyncThunk } from '@reduxjs/toolkit';
import { setErrorFeedback, setSuccessFeedback } from '../feedback/feedbackSlice';
import { fetchAll, fetchById, fetchAtributosTratamentosTributarios } from './tratamentoTributarioAPI';

const fetchAllAsync = createAsyncThunk(
  'tratamentoTributario/fetchAll',
  async () => {
    const { data } = await fetchAll();
    return { data };
  }
);

const fetchByIdAsync = createAsyncThunk(
  'tratamentoTributario/fetchById',
  async (data) => {
    const response = await fetchById(data);
    return {
      response: {
        status: response.status,
        data: response.data,
      },
    };
  }
);

const fetchAsyncAtributosTratamentosTributarios = createAsyncThunk(
  'tratamentoTributario/fetchAsyncAtributosTratamentosTributarios',
  async (data, { rejectWithValue, dispatch }) => {
   
      let response;

      try {
        response = await fetchAtributosTratamentosTributarios(data);
      } catch(error) {
        dispatch(setErrorFeedback({ message: error?.response?.data || "Erro desconhecido !!" }));
        return rejectWithValue(error?.response?.data);
      } 

      if (response?.status === 200) {
        dispatch(setSuccessFeedback({ message: response?.data }));
      }
  
      if (response?.status === 500 || response?.status === 404 || response?.status === 401) {
        const message = response?.data ? response?.data : 'Erro não esperado!';
        dispatch(setErrorFeedback({ message }));
      }
      
      return {
        response: {
          status: response.status,
          data: response.data,
        },
      };
  }
);

const tratamentoTributarioThunks = {
  fetchAllAsync,
  fetchByIdAsync,
  fetchAsyncAtributosTratamentosTributarios,
};

export {
  tratamentoTributarioThunks,
  fetchAllAsync,
  fetchByIdAsync,
  fetchAsyncAtributosTratamentosTributarios,
};
