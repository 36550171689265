import { MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import QCXTextField from '../text-field/QCXTextField';

const mercadoriaTiposDeclaracao = {
  DE: 'DE',
  DI: 'DI',
  DUIMP: 'DUIMP',
  DUE: 'DUE',
};

export default function QCXSelectMercadoriaTipoDeclaracaoVinculada({ value, onChange, ...restProps }) {
  const { t } = useTranslation();

  return (
    <QCXTextField
      style={{ width: '100%' }}
      label="Tipo Declaração Vinculada"
      key="tipo-declaracao-vinculada"
      id="tipo-declaracao-vinculada"
      select
      value={value}
      onChange={onChange}
      {...restProps}
    >
      <MenuItem default selected value="">
        {t('com.muralis.qcx.selecionePlaceholder')}
      </MenuItem>
      {Object.keys(mercadoriaTiposDeclaracao).map((key) => (
        <MenuItem key={`select-item-tipo-declaracao-vinculada-${key}`} value={key}>
          {key}
        </MenuItem>
      ))}
    </QCXTextField>
  );
}
