import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllAsync, selectConfigControl, selectEstados } from '../../features/estado/estadoSlice';
import { isAutoLoadControl } from '../../utils/store/store-utils';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';

export default function QCXSelectEstadoAutocomplete({
  label,
  name,
  initialValues,
  getOptionLabel = (item) => (item?.code && item?.description ? `${item?.description} - ${item?.code}` : ''),
  filter = {
    options: {
      stringify: (item) => `${item?.description} - ${item?.code}`,
    },
  },
  ...restProps
}) {
  const dispatch = useDispatch();

  const list = useSelector(selectEstados);
  const configControl = useSelector(selectConfigControl);

  useEffect(() => {
    if (isAutoLoadControl(configControl)) {
      dispatch(fetchAllAsync());
    }
  }, [configControl]);

  const [defaultValue, setDefaultValue] = useState('');

  useEffect(() => {
    const value = list.find((item) => item?.id === _.get(initialValues, name));
    if (value) {
      setDefaultValue(value);
    } else {
      setDefaultValue({ id: null, description: '' });
    }
  }, [list, initialValues]);

  const filterOptions = createFilterOptions(filter?.options);

  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={list}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={getOptionLabel}
      {...restProps}
    />
  );
}
