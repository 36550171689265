import { Grid, makeStyles } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { isArray, isEmpty, debounce } from 'lodash';
import QCXFormPaginationControl from './QCXFormPaginationControl';
import QCXControlButtonsPagination from './QCXControlButtonsPagination';

const useStyles = makeStyles((theme) => ({
  paginationButtons: {
    paddingTop: '16px',
    [theme.breakpoints.up('md')]: {
      marginLeft: '-16px',
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: '-48px',
    },
  },
}));

export default function QCXDualFormPaginationControl({
  initialValues,
  list,
  autoLoadItem,
  currentItemNumber,
  isLoading,
  isUpdate,
  isSubConsult,
  isSubUpdate,
  isSubCreate,
  isSubBackgroundCreate,
  isSubBackgroundConsult,
  isSubBackgroundUpdate,
  isSubBackgroundDelete,
  isLastModeBackgroundDelete,
  defaultItem = undefined,
  defaultParent = undefined,
  ignoreDefaultItem,
  loadInitialItem,
  autoFormReset = true,
  controlButtonsPaginationProps = {
    primary: {},
    secondary: {},
  },
  handleSetLoadInitialItem,
  handleSetCurrentItemNumber,
  handleSetIgnoreDefaultItem,
  handleChangeModel,
  handleChangeItemDecorator,
  handleLoadingStatus,
  handleResetStatus,
  handleChangeToConsult,
  handleChangeToSubConsult,
  handleInternalClearForm,
  getSubListByParent,
  ...restProps
}) {
  const classes = useStyles();

  const [currentParentNumber, setCurrentParentNumber] = useState(defaultParent || 1);
  const [loadInternalInitialItem, setLoadInternalInitialItem] = useState(loadInitialItem);

  const sortedList = useMemo(() => {
    const sortByParentNumber = (a, b) => {
      const mercadoriasA = a?.mercadorias || [];
      const mercadoriasB = b?.mercadorias || [];

      const firstMercadoriaA = mercadoriasA[0];
      const firstMercadoriaB = mercadoriasB[0];

      const numberAdicaoA = firstMercadoriaA?.metadata?.parent?.numero;
      const numberAdicaoB = firstMercadoriaB?.metadata?.parent?.numero;

      return numberAdicaoA - numberAdicaoB;
    };

    const sortedData = [...list].sort(sortByParentNumber);

    return sortedData;
  }, [list]);

  const items = useMemo(() => {
    if (isSubCreate || isSubUpdate) {
      return sortedList;
    }

    const parentIndex = currentParentNumber - 1;

    const parent = sortedList[parentIndex];

    const subList = getSubListByParent(parent);

    handleChangeToSubConsult();
    setLoadInternalInitialItem(true);

    return subList;
  }, [sortedList, isSubCreate, isSubUpdate, currentParentNumber, setLoadInternalInitialItem, getSubListByParent]);

  const hasParentItems = useMemo(() => isArray(sortedList) && !isEmpty(sortedList), [sortedList]);

  const addedParentItemsQuantity = useMemo(() => sortedList.length, [sortedList]);

  const parentItemsPaginationInfo = useMemo(
    () => `${currentParentNumber} / ${addedParentItemsQuantity}`,
    [currentParentNumber, addedParentItemsQuantity]
  );

  const handleSetCurrentParentNumber = useCallback(
    (newCurrentParentNumber) => {
      setCurrentParentNumber(newCurrentParentNumber);
      handleSetCurrentItemNumber(1);
    },
    [setCurrentParentNumber, handleSetCurrentItemNumber]
  );

  const handleNextItem = useCallback(() => {
    handleLoadingStatus();

    const executeDebouncedNext = debounce(() => {
      const nextItemNumber = Math.max(1, currentParentNumber) + 1;

      handleSetCurrentParentNumber(nextItemNumber);
      setLoadInternalInitialItem(true);

      handleResetStatus();
    }, 500);

    if (hasParentItems) {
      executeDebouncedNext();
    }
  }, [
    hasParentItems,
    currentParentNumber,
    handleLoadingStatus,
    handleSetCurrentParentNumber,
    setLoadInternalInitialItem,
    currentItemNumber,
  ]);

  const handlePreviousItem = useCallback(() => {
    handleLoadingStatus();

    const executeDebouncedPrevious = debounce(() => {
      const previousItemNumber = Math.max(1, currentParentNumber) - 1;

      handleSetCurrentParentNumber(previousItemNumber);
      setLoadInternalInitialItem(true);

      handleResetStatus();
    }, 500);

    if (hasParentItems) {
      executeDebouncedPrevious();
    }
  }, [
    hasParentItems,
    currentParentNumber,
    handleLoadingStatus,
    handleSetCurrentParentNumber,
    setLoadInternalInitialItem,
  ]);

  const handleFirstItem = useCallback(() => {
    handleLoadingStatus();

    const executeDebouncedFirst = debounce(() => {
      const firstItemNumber = 1;

      handleSetCurrentParentNumber(firstItemNumber);
      setLoadInternalInitialItem(true);

      handleResetStatus();
    }, 500);

    if (hasParentItems) {
      executeDebouncedFirst();
    }
  }, [hasParentItems, handleLoadingStatus, handleSetCurrentParentNumber, setLoadInternalInitialItem]);

  const handleLastItem = useCallback(() => {
    handleLoadingStatus();

    const executeDebouncedLast = debounce(() => {
      const lastItemNumber = sortedList?.length;

      handleSetCurrentParentNumber(lastItemNumber);
      setLoadInternalInitialItem(true);

      handleResetStatus();
    }, 500);

    if (hasParentItems) {
      executeDebouncedLast();
    }
  }, [sortedList, hasParentItems, handleLoadingStatus, handleSetCurrentParentNumber, setLoadInternalInitialItem]);

  useEffect(() => {
    if (isSubUpdate) {
      const adjustedDefaultItem = items?.findIndex((data) => data?.item === defaultItem) + 1;

      handleSetCurrentItemNumber(adjustedDefaultItem);
    }
  }, [items, isSubUpdate, defaultItem, handleSetCurrentItemNumber]);

  return (
    <Grid item container alignItems="center" justify="flex-end" xs={12}>
      {!(isSubCreate || isSubUpdate) && (
        <Grid
          item
          container
          className={classes.paginationButtons}
          alignItems="center"
          justify="flex-end"
          sm={12}
          md={6}
          lg={7}
        >
          <QCXControlButtonsPagination
            metadata={{
              ...controlButtonsPaginationProps?.primary,
              paginationInfoField: {
                ...controlButtonsPaginationProps?.primary?.paginationInfoField,
                value: parentItemsPaginationInfo,
                hiddenValue: controlButtonsPaginationProps?.primary?.paginationInfoField?.hiddenValue,
              },
              current: currentParentNumber,
              quantity: addedParentItemsQuantity,
            }}
            isSubConsult={isSubConsult}
            isSubUpdate={isSubUpdate}
            isSubCreate={isSubCreate}
            handleFirstItem={handleFirstItem}
            handlePreviousItem={handlePreviousItem}
            handleNextItem={handleNextItem}
            handleLastItem={handleLastItem}
            disabled={isLoading}
          />
        </Grid>
      )}
      <Grid
        item
        container
        className={classes.paginationButtons}
        alignItems="center"
        justify="flex-end"
        sm={12}
        md={6}
        lg={5}
      >
        <QCXFormPaginationControl
          initialValues={initialValues}
          list={items}
          loadInitialItem={loadInternalInitialItem}
          currentItemNumber={currentItemNumber}
          currentParentNumber={currentParentNumber}
          isLoading={isLoading}
          isUpdate={isUpdate}
          isSubConsult={isSubConsult}
          isSubUpdate={isSubUpdate}
          isSubCreate={isSubCreate}
          isSubBackgroundCreate={isSubBackgroundCreate}
          isSubBackgroundConsult={isSubBackgroundConsult}
          isSubBackgroundUpdate={isSubBackgroundUpdate}
          isSubBackgroundDelete={isSubBackgroundDelete}
          isLastModeBackgroundDelete={isLastModeBackgroundDelete}
          defaultItem={defaultItem}
          ignoreDefaultItem={ignoreDefaultItem}
          controlButtonsPaginationProps={controlButtonsPaginationProps?.secondary}
          handleSetLoadInitialItem={handleSetLoadInitialItem}
          handleSetCurrentItemNumber={handleSetCurrentItemNumber}
          handleChangeModel={handleChangeModel}
          handleChangeItemDecorator={handleChangeItemDecorator}
          handleLoadingStatus={handleLoadingStatus}
          handleResetStatus={handleResetStatus}
          handleChangeToConsult={handleChangeToConsult}
          handleChangeToSubConsult={handleChangeToSubConsult}
          handleSetIgnoreDefaultItem={handleSetIgnoreDefaultItem}
          handleInternalClearForm={handleInternalClearForm}
          autoFormReset={autoFormReset}
          controlled
          {...restProps}
        />
      </Grid>
    </Grid>
  );
}
