import {
  doGetQCXRequest,
} from '../../utils/api/domain/domain-api-utils';
import { TRATAMENTO_TRIBUTARIO_ENDPOINT } from '../../utils/api/domain/domain-endpoint-utils';

async function fetchAll() {
  return doGetQCXRequest([
    TRATAMENTO_TRIBUTARIO_ENDPOINT
  ]);
}

async function fetchById(id) {
  return doGetQCXRequest([
    TRATAMENTO_TRIBUTARIO_ENDPOINT,
    id
  ]);
}

async function fetchAtributosTratamentosTributarios(id) {
  return doGetQCXRequest([
    TRATAMENTO_TRIBUTARIO_ENDPOINT,
    'obter-atributos-tratamentos',
    id
  ]);
}

const tratamentoTributarioAPI = {
  fetchAll,
  fetchById,
  fetchAtributosTratamentosTributarios,
};

export {
  tratamentoTributarioAPI,
  fetchAll,
  fetchById,
  fetchAtributosTratamentosTributarios,
};
