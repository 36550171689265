/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import { Grid, makeStyles } from '@material-ui/core';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import {
  armazemDeclaracaoImportacaoActions,
  armazemDeclaracaoImportacaoSelectors,
} from '../../features/armazem-declaracao-importacao/armazemDeclaracaoImportacaoSlice';
import {
  embalagemDeclaracaoImportacaoActions,
  embalagemDeclaracaoImportacaoSelectors,
} from '../../features/embalagem-declaracao-importacao/embalagemDeclaracaoImportacaoSlice';
import {
  exportacaoEstrangeiraDeclaracaoImportacaoActions,
  exportacaoEstrangeiraDeclaracaoImportacaoSelectors,
} from '../../features/exportacao-estrangeira-declaracao-importacao/exportacaoEstrangeiraDeclaracaoImportacaoSlice';
import { selectPaises } from '../../features/pais/paisSlice';
import { setList as setRecintoAduaneiroList } from '../../features/recinto-aduaneiro/recintoAduaneiroSlice';
import { fetchByFilterAsync as filterSetorLotacao } from '../../features/setor-lotacao/setorLotacaoSlice';
import { selectTiposDeclaracao } from '../../features/tipo-declaracao/tipoDeclaracaoSlice';
import { fetchById as fetchTipoEmbalagemNacionalById } from '../../features/tipo-embalagem-nacional/tipoEmbalagemNacionalAPI';
import { selectUnidadesLocaisRfb } from '../../features/unidade-local-rfb/unidadeLocalRfbSlice';
import QCXWarningAlert from '../../shared-components/alert/QCXWarningAlert';
import QCXFinalCheckboxField from '../../shared-components/final-checkbox-field/QCXFinalCheckboxField';
import QCXFinalDatePickerField from '../../shared-components/final-date-picker-field/QCXFinalDatePickerField';
import QCXFinalBondManagerOld from '../../shared-components/final-gerenciador-relacionamento/QCXFinalBondManagerOld';
import QCXFinalNumericDecimalField from '../../shared-components/final-numeric-decimal-field/QCXFinalNumericDecimalField';
import QCXFinalNumericIntegerField from '../../shared-components/final-numeric-integer-field/QCXFinalNumericIntegerField';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXFormStepWizard from '../../shared-components/form-step-wizard/QCXFormStepWizard';
import QCXSelectPaisAutocomplete from '../../shared-components/select-pais/QCXSelectPaisAutocomplete';
import QCXSelectRecintoAduaneiroAutocomplete from '../../shared-components/select-recinto-aduaneiro/QCXSelectRecintoAduaneiroAutocomplete';
import QCXSelectSetorLotacaoAutocomplete from '../../shared-components/select-setor-lotacao/QCXSelectSetorLotacaoAutocomplete';
import {
  TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PE_PRODUTO_ESTRANGEIRO,
  TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PI_PRODUTO_INDUSTRIALIZADO,
  TIPO_DECLARACAO_INTERNACAO_DE_ALC_AREA_DE_LIVRE_COMERCIO,
  TIPO_DECLARACAO_NACIONALIZACAO_DE_ADMISSAO_TEMPORARIA,
  TIPO_DECLARACAO_NACIONALIZACAO_DE_DEA_DEPOSITO_ESPECIAL_ALFANDEGADO,
  TIPO_DECLARACAO_NACIONALIZACAO_DE_ENTREPOSTO_ADUANEIRO,
  TIPO_DECLARACAO_NACIONALIZACAO_DE_REGIME_ADUANEIRO_GERAL,
  TIPO_DECLARACAO_SAIDA_DE_EIZOF_ENTREPOSTO_INTERNACIONAL_DA_ZFM,
  TIPO_DECLARACAO_SAIDA_DE_ENTREPOSTO_INDUSTRIAL,
  configureGeneralTipoDeclaracaoMatcherWith,
} from '../../shared-components/select-tipo-declaracao/tipoDeclaracaoUtils';
import QCXSelectTipoEmbalagemAutocomplete from '../../shared-components/select-tipo-embalagem/QCXSelectTipoEmbalagemAutocomplete';
import QCXSelectTransportadorInternacionalAutocomplete from '../../shared-components/select-transportador-internacional/QCXSelectTransportadorInternacionalAutocomplete';
import QCXSelectUnidadeLocalRFBAutocomplete from '../../shared-components/select-unidade-local-rfb/QCXSelectUnidadeLocalRFBAutocomplete';
import { isStrictEquals } from '../../utils/general/general-utils';
import QCXFormSubtitle from '../form-title/QCXFormSubtitle';
import QCXFormTitle from '../form-title/QCXFormTitle';
import ConfirmationModal from '../../ts/pages/integracao/planilhas/components/confirmationModal';

const useStyles = makeStyles((theme) => ({
  gridFields: {
    paddingBottom: '16px',
  },
  customGridFields: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: '16px',
    },
    paddingBottom: '16px',
  },
}));

export default function QCXDeclaracaoImportacaoCargaForm({
  initialValues,
  handleSubmit,
  isConsult,
  isCreate,
  isLocked,
  controlComponentProps,
  ...restProps
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  const dispatch = useDispatch();

  const unidadesLocaisRfb = useSelector(selectUnidadesLocaisRfb);
  const tiposDeclaracao = useSelector(selectTiposDeclaracao);

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [decisionPromiseResolver, setDecisionPromiseResolver] = useState(null);

  const showConfirmationModal = (message) => {
    setConfirmModalOpen(true);

    return new Promise((resolve) => {
      setDecisionPromiseResolver(() => resolve);
    });
  };

  const handleConfirm = () => {
    setConfirmModalOpen(false);
    decisionPromiseResolver(true);
  };

  const handleCancel = () => {
    setConfirmModalOpen(false);
    decisionPromiseResolver(false);
  };
  const embalagemReducerConfig = useMemo(
    () => ({
      selectors: {
        selectStatus: embalagemDeclaracaoImportacaoSelectors.selectStatus,
        selectMode: embalagemDeclaracaoImportacaoSelectors.selectMode,
        selectModel: embalagemDeclaracaoImportacaoSelectors.selectModel,
      },
      actions: {
        loading: embalagemDeclaracaoImportacaoActions.loading,
        resetStatus: embalagemDeclaracaoImportacaoActions.resetStatus,
        changeToUpdateMode: embalagemDeclaracaoImportacaoActions.changeToUpdateMode,
        changeToCreateMode: embalagemDeclaracaoImportacaoActions.changeToCreateMode,
        resetMode: embalagemDeclaracaoImportacaoActions.resetMode,
        setModel: embalagemDeclaracaoImportacaoActions.setModel,
        resetModel: embalagemDeclaracaoImportacaoActions.resetModel,
      },
    }),
    []
  );

  const embalagemFormProps = useMemo(
    () => ({
      rootName: 'ignorableFields.embalagem',
      fields: [
        {
          name: 'tipoEmbalagemNacional.id',
          label: t('com.muralis.qcx.embalagem.tipoEmbalagem').toUpperCase(),
        },
        {
          name: 'quantidade',
          label: t('com.muralis.qcx.quantidade.label').toUpperCase(),
        },
      ],
    }),
    []
  );

  const embalagemListProps = useMemo(
    () => ({
      name: 'atributosAdicionais.carga.quantidades',
      columns: [
        {
          field: 'tipoEmbalagemNacional.description',
          headerName: t('com.muralis.qcx.embalagem.tipoEmbalagem'),
          headerAlign: 'left',
          align: 'left',
          flex: 90,
          valueGetter: ({ row }) => row?.tipoEmbalagemNacional?.description || '-',
        },
        {
          field: 'quantidade',
          headerName: t('com.muralis.qcx.quantidade.label'),
          headerAlign: 'center',
          align: 'center',
          flex: 90,
          valueGetter: ({ row }) => row?.quantidade || '-',
        },
      ],
    }),
    []
  );

  const exportacaoEstrangeiraFormProps = useMemo(() => ({
    rootName: 'ignorableFields.de',
    fields: [
      {
        name: 'numero',
        label: 'Número da DE',
      },
      {
        name: 'inicio',
        label: 'Início',
      },
      {
        name: 'fim',
        label: 'Fim',
      },
    ],
  }));

  const exportacaoEstrangeiraListProps = useMemo(
    () => ({
      name: 'atributosAdicionais.carga.declaracaoExportacoes',
      columns: [
        {
          field: 'number',
          headerName: 'Número da DE',
          headerAlign: 'left',
          align: 'left',
          flex: 180,
          valueGetter: ({ row }) => row?.numero || '-',
        },
        {
          field: 'start',
          headerName: 'Início',
          headerAlign: 'center',
          align: 'center',
          flex: 90,
          valueGetter: ({ row }) => row?.inicio || '-',
        },
        {
          field: 'end',
          headerName: 'Fim',
          headerAlign: 'center',
          align: 'center',
          flex: 90,
          valueGetter: ({ row }) => row?.fim || '-',
        },
      ],
    }),
    []
  );

  const handleAddExportacaoExt = useCallback((currentVinculo, currentVinculos, handleSuccessAddVinculo) => {
    const vinculo = {
      id: uuid(),
      numero: currentVinculo.numero,
      inicio: currentVinculo.inicio,
      fim: currentVinculo.fim,
    };

    const updatedVinculos = [...currentVinculos, vinculo];

    handleSuccessAddVinculo(updatedVinculos);
  }, []);

  const handleUpdateExportacaoExt = useCallback((currentVinculo, currentVinculos, handleSuccessUpdateVinculo) => {
    const updatedVinculos =
      currentVinculos?.map((existingVinculo) =>
        isStrictEquals(existingVinculo?.id, currentVinculo?.id) ? currentVinculo : existingVinculo
      ) || currentVinculos;

    handleSuccessUpdateVinculo(updatedVinculos);
  });

  const handleAddEmbalagem = useCallback(async (currentVinculo, currentVinculos, handleSuccessAddVinculo) => {

    if (currentVinculos.length >= 1) {
      const userDecision = await showConfirmationModal("Já existe uma embalagem registrada. Deseja continuar?");
      if (!userDecision) {
        return;
      }
    }

    const tipoEmbalagemNacionalResponse = await fetchTipoEmbalagemNacionalById(
      currentVinculo?.tipoEmbalagemNacional?.id
    );

    if (tipoEmbalagemNacionalResponse?.status === 200) {
      const foundTipoEmbalagemNacional = tipoEmbalagemNacionalResponse?.data;

      const vinculo = {
        id: uuid(),
        tipoEmbalagemNacional: foundTipoEmbalagemNacional,
        quantidade: currentVinculo?.quantidade,
      };

      const updatedVinculos = [...currentVinculos, vinculo];

      handleSuccessAddVinculo(updatedVinculos);
    }
  }, []);

  const handleUpdateEmbalagem = useCallback(async (currentVinculo, currentVinculos, handleSuccessUpdateVinculo) => {
    const tipoEmbalagemNacionalResponse = await fetchTipoEmbalagemNacionalById(
      currentVinculo?.tipoEmbalagemNacional?.id
    );

    if (tipoEmbalagemNacionalResponse?.status === 200) {
      const foundTipoEmbalagemNacional = tipoEmbalagemNacionalResponse?.data;

      const updatedVinculo = {
        ...currentVinculo,
        tipoEmbalagemNacional: foundTipoEmbalagemNacional,
        quantidade: currentVinculo?.quantidade,
      };

      const updatedVinculos =
        currentVinculos?.map((existingVinculo) =>
          isStrictEquals(existingVinculo?.id, updatedVinculo?.id) ? updatedVinculo : existingVinculo
        ) || currentVinculos;

      handleSuccessUpdateVinculo(updatedVinculos);
    }
  }, []);

  const handleEmbalagemAlreadyExists = useCallback(
    (currentVinculo, currentVinculosList) =>
      currentVinculosList.some(
        (vinculo) =>
          isStrictEquals(vinculo?.tipoEmbalagemNacional?.id, currentVinculo?.tipoEmbalagemNacional?.id) &&
          isStrictEquals(vinculo?.quantidade, currentVinculo?.quantidade) &&
          !isStrictEquals(vinculo?.id, currentVinculo?.id)
      ),
    []
  );

  const exportacaoEstrangeiraReducerConfig = useMemo(
    () => ({
      selectors: {
        selectStatus: exportacaoEstrangeiraDeclaracaoImportacaoSelectors.selectStatus,
        selectMode: exportacaoEstrangeiraDeclaracaoImportacaoSelectors.selectMode,
        selectModel: exportacaoEstrangeiraDeclaracaoImportacaoSelectors.selectModel,
      },
      actions: {
        loading: exportacaoEstrangeiraDeclaracaoImportacaoActions.loading,
        resetStatus: exportacaoEstrangeiraDeclaracaoImportacaoActions.resetStatus,
        changeToUpdateMode: exportacaoEstrangeiraDeclaracaoImportacaoActions.changeToUpdateMode,
        changeToCreateMode: exportacaoEstrangeiraDeclaracaoImportacaoActions.changeToCreateMode,
        resetMode: exportacaoEstrangeiraDeclaracaoImportacaoActions.resetMode,
        setModel: exportacaoEstrangeiraDeclaracaoImportacaoActions.setModel,
        resetModel: exportacaoEstrangeiraDeclaracaoImportacaoActions.resetModel,
      },
    }),
    []
  );

  const armazemReducerConfig = useMemo(
    () => ({
      selectors: {
        selectStatus: armazemDeclaracaoImportacaoSelectors.selectStatus,
        selectMode: armazemDeclaracaoImportacaoSelectors.selectMode,
        selectModel: armazemDeclaracaoImportacaoSelectors.selectModel,
      },
      actions: {
        loading: armazemDeclaracaoImportacaoActions.loading,
        resetStatus: armazemDeclaracaoImportacaoActions.resetStatus,
        changeToUpdateMode: armazemDeclaracaoImportacaoActions.changeToUpdateMode,
        changeToCreateMode: armazemDeclaracaoImportacaoActions.changeToCreateMode,
        resetMode: armazemDeclaracaoImportacaoActions.resetMode,
        setModel: armazemDeclaracaoImportacaoActions.setModel,
        resetModel: armazemDeclaracaoImportacaoActions.resetModel,
      },
    }),
    []
  );

  const armazemFormProps = useMemo(
    () => ({
      rootName: 'ignorableFields.armazem',
      fields: [
        {
          name: 'value',
          label: t('com.muralis.qcx.armazem.labelSingular').toUpperCase(),
        },
      ],
    }),
    []
  );

  const armazemListProps = useMemo(
    () => ({
      name: 'atributosAdicionais.carga.armazens',
      columns: [
        {
          field: 'value',
          headerName: t('com.muralis.qcx.armazem.labelSingular'),
          headerAlign: 'left',
          align: 'left',
          flex: 90,
          valueGetter: ({ row }) => row?.value || '',
        },
      ],
    }),
    []
  );

  const handleAddArmazem = useCallback(async (currentVinculo, currentVinculos, handleSuccessAddVinculo) => {
    const vinculoToAdd = {
      id: uuid(),
      value: currentVinculo?.value,
    };

    const updatedVinculos = [...currentVinculos, vinculoToAdd];

    handleSuccessAddVinculo(updatedVinculos);
  }, []);

  const handleUpdateArmazem = useCallback(async (currentVinculo, currentVinculos, handleSuccessUpdateVinculo) => {
    const updatedVinculos =
      currentVinculos?.map((existingVinculo) =>
        isStrictEquals(existingVinculo.id, currentVinculo?.id) ? currentVinculo : existingVinculo
      ) || currentVinculos;

    handleSuccessUpdateVinculo(updatedVinculos);
  }, []);

  const handleExportacaoAlreadyExists = useCallback(
    (currentVinculo, currentVinculosList) =>
      currentVinculosList.some((vinculo) => isStrictEquals(vinculo.number, currentVinculo.number)),
    []
  );

  const handleArmazemAlreadyExists = useCallback(
    (currentVinculo, currentVinculosList) =>
      currentVinculosList.some((vinculo) => isStrictEquals(vinculo.value, currentVinculo?.value)),
    []
  );

  useEffect(() => {
    const handleLoadRecintoAduaneiroAndSetorLotacaoByUnidadeLocalRfbId = (unidadeLocalRfbId) => {
      const unidadeLocalRfbSelected = unidadesLocaisRfb.find((item) => item?.id === unidadeLocalRfbId);

      dispatch(
        filterSetorLotacao([
          {
            name: 'unidadeLocalRfb',
            value: unidadeLocalRfbId,
          },
        ])
      );

      const recintosAduaneiros = unidadeLocalRfbSelected?.recintosAduaneiro || [];

      dispatch(
        setRecintoAduaneiroList({
          data: recintosAduaneiros,
        })
      );
    };

    if (initialValues?.urfDespacho?.id) {
      handleLoadRecintoAduaneiroAndSetorLotacaoByUnidadeLocalRfbId(initialValues?.urfDespacho?.id);
    }
  }, []);

  const tipoDeclaracao = useMemo(() => initialValues?.atributosAdicionais?.tipoDeclaracao?.id, [initialValues]);

  const isSomeTipoDeclaracaoBy = useCallback(
    (codesToCompare) => configureGeneralTipoDeclaracaoMatcherWith(tiposDeclaracao)(tipoDeclaracao, codesToCompare),
    [tiposDeclaracao, tipoDeclaracao]
  );

  const isVisibleByTipoDeclaracao = useMemo(
    () =>
      !isSomeTipoDeclaracaoBy([
        TIPO_DECLARACAO_NACIONALIZACAO_DE_ADMISSAO_TEMPORARIA,
        TIPO_DECLARACAO_NACIONALIZACAO_DE_ENTREPOSTO_ADUANEIRO,
        TIPO_DECLARACAO_SAIDA_DE_EIZOF_ENTREPOSTO_INTERNACIONAL_DA_ZFM,
        TIPO_DECLARACAO_SAIDA_DE_ENTREPOSTO_INDUSTRIAL,
        TIPO_DECLARACAO_NACIONALIZACAO_DE_DEA_DEPOSITO_ESPECIAL_ALFANDEGADO,
        TIPO_DECLARACAO_NACIONALIZACAO_DE_REGIME_ADUANEIRO_GERAL,
        TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PI_PRODUTO_INDUSTRIALIZADO,
        TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PE_PRODUTO_ESTRANGEIRO,
        TIPO_DECLARACAO_INTERNACAO_DE_ALC_AREA_DE_LIVRE_COMERCIO,
      ]),
    [isSomeTipoDeclaracaoBy]
  );

  const list = useSelector(selectPaises);

  function shouldShowDe(values) {
    const value = list.find((item) => item?.id === _.get(values, 'paisProcedencia.id'));

    if (!value) {
      return false;
    }

    return value.code === '63' || value.code === '105' || value.code === '845' || value.code === '586';
  }

  return (
    <QCXFormStepWizard
      initialValues={initialValues}
      onSubmit={handleSubmit}
      controlComponentProps={controlComponentProps}
      disablePristine
      {...restProps}
    >
      {({ values }) => (
        <>
          <ConfirmationModal
            open={confirmModalOpen}
            message="Já existe uma embalagem registrada. Deseja continuar?"
            singleAction={false}
            onConfirm={handleConfirm}
            onCancel={handleCancel}
            title="Confirmação"
            
          />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <QCXFormTitle title={t('com.muralis.qcx.carga.fichaCarga')} />
            </Grid>
            <Grid item container alignContent="flex-start" xs={12} sm={12} md={6} lg={6}>
              <Grid item className={classes.gridFields} xs={12} sm={12} md={12} lg={12}>
                <QCXSelectPaisAutocomplete
                  name="paisProcedencia.id"
                  label={t('com.muralis.qcx.paisProcedencia')}
                  initialValues={values}
                  disabled={isConsult}
                />
              </Grid>
              {isVisibleByTipoDeclaracao && (
                <Grid item className={classes.gridFields} xs={12} sm={12} md={12} lg={12}>
                  <QCXFinalDatePickerField
                    id="date-picker-data-chegada-carga-field"
                    key="date-picker-data-chegada-carga-field"
                    name="atributosAdicionais.carga.dataChegada"
                    label={t('com.muralis.qcx.dataChegada')}
                    disabled={isConsult}
                  />
                </Grid>
              )}
              <Grid item className={classes.gridFields} xs={12} sm={12} md={12} lg={12}>
                <QCXSelectUnidadeLocalRFBAutocomplete
                  id="autocomplete-select-urf-entrada-field"
                  key="autocomplete-select-urf-entrada-field"
                  name="urfEntrada.id"
                  label={t('com.muralis.qcx.URFEntradaPais')}
                  initialValues={values}
                  disabled={isConsult}
                />
              </Grid>
              {isVisibleByTipoDeclaracao && (
                <Grid item className={classes.gridFields} xs={12} sm={12} md={12} lg={12}>
                  <QCXSelectTransportadorInternacionalAutocomplete
                    id="autocomplete-select-agente-transportador-field"
                    key="autocomplete-select-agente-transportador-field"
                    name="atributosAdicionais.carga.agenteTransportador"
                    label={t('com.muralis.qcx.transportador.agenteTransportador')}
                    initialValues={values}
                    agenteTransportador
                    disabled={isConsult}
                  />
                </Grid>
              )}
            </Grid>
            <Grid item container alignContent="flex-start" xs={12} sm={12} md={6} lg={6}>
              <Grid item className={classes.gridFields} xs={12} sm={12} md={12} lg={12}>
                <QCXFinalNumericDecimalField
                  id="text-number-peso-bruto-field"
                  key="text-number-peso-bruto-field"
                  name="pesoBruto"
                  label={t('com.muralis.qcx.pesoBruto')}
                  scale={5}
                  disabled={isConsult}
                />
              </Grid>
              <Grid item className={classes.gridFields} xs={12} sm={12} md={12} lg={12}>
                <QCXFinalNumericDecimalField
                  id="text-number-peso-liquido-field"
                  key="text-number-peso-liquido-field"
                  name="pesoLiquido"
                  label={t('com.muralis.qcx.pesoLiquido')}
                  scale={5}
                  disabled={isConsult}
                />
              </Grid>
              <Grid item className={classes.gridFields} xs={12} sm={12} md={12} lg={12}>
                <QCXFinalCheckboxField
                  id="checkbox-ratear-peso-liquido-itens-field"
                  key="checkbox-ratear-peso-liquido-itens-field"
                  name="rateiaPesoLiquidoItem"
                  label={t('com.muralis.qcx.ratearPesoLiquidoItens')}
                  disabled={isConsult}
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <QCXFormSubtitle title={t('com.muralis.qcx.embalagem.labelPlural')} />
            </Grid>
            <QCXFinalBondManagerOld
              isParentConsult={isConsult}
              listProps={embalagemListProps}
              formProps={embalagemFormProps}
              reducerConfig={embalagemReducerConfig}
              handleAdd={handleAddEmbalagem}
              handleUpdate={handleUpdateEmbalagem}
              handleAlreadyExists={handleEmbalagemAlreadyExists}
            >
              <Grid item className={classes.gridFields} xs={12} sm={12} md={4} lg={4}>
                <QCXSelectTipoEmbalagemAutocomplete
                  id="autocomplete-select-embalagem-tipo-field"
                  key="autocomplete-select-embalagem-tipo-field"
                  name="ignorableFields.embalagem.tipoEmbalagemNacional.id"
                  label={t('com.muralis.qcx.embalagem.tipoEmbalagem')}
                  initialValues={values}
                  disabled={isConsult}
                />
              </Grid>
              <Grid item className={classes.customGridFields} xs={12} sm={12} md={4} lg={4}>
                <QCXFinalNumericIntegerField
                  id="numeric-integer-embalagem-quantidade-field"
                  key="numeric-integer-embalagem-quantidade-field"
                  name="ignorableFields.embalagem.quantidade"
                  label={t('com.muralis.qcx.quantidade.label')}
                  allowNegative={false}
                  disabled={isConsult}
                />
              </Grid>
            </QCXFinalBondManagerOld>

            <Grid item xs={12}>
              <QCXFormSubtitle title={t('com.muralis.qcx.recintoSetor')} />
            </Grid>
            {!isConsult && !values?.urfDespacho?.id && (
              <Grid item xs={12}>
                <QCXWarningAlert>
                  <Trans i18nKey="com.muralis.qcx.mensagem.preenchaCampoFichaHabilitarNovosCampos">
                    <strong>
                      {{
                        campo: t('com.muralis.qcx.fatura.URFdespacho'),
                      }}
                    </strong>
                    <em>
                      {{
                        ficha: t('com.muralis.qcx.basicas'),
                      }}
                    </em>
                    <strong>
                      {{
                        campoAduaneiro: t('com.muralis.qcx.recintoAduaneiro').toUpperCase(),
                      }}
                    </strong>
                    <em>
                      {{
                        setorLotacao: t('com.muralis.qcx.setorLotacao').toUpperCase(),
                      }}
                    </em>
                  </Trans>
                </QCXWarningAlert>
              </Grid>
            )}
            <Grid item className={classes.gridFields} xs={12} sm={12} md={6} lg={6}>
              <QCXSelectRecintoAduaneiroAutocomplete
                name="atributosAdicionais.carga.recintoAduaneiro.id"
                label={t('com.muralis.qcx.recintoAduaneiro')}
                initialValues={values}
                disabled={isConsult || !values?.urfDespacho?.id}
              />
            </Grid>
            <Grid item className={classes.gridFields} xs={12} sm={12} md={6} lg={6}>
              <QCXSelectSetorLotacaoAutocomplete
                recintoAduaneiroId={values?.atributosAdicionais?.carga?.recintoAduaneiro?.id ?? ''}
                name="atributosAdicionais.carga.setorLotacao.id"
                label={t('com.muralis.qcx.setorLotacao')}
                initialValues={values}
                disabled={
                  isConsult || !values?.urfDespacho?.id || !values?.atributosAdicionais?.carga?.recintoAduaneiro?.id
                }
              />
            </Grid>
            <Grid item xs={12}>
              <QCXFormSubtitle title={t('com.muralis.qcx.armazem.labelPlural')} />
            </Grid>
            <QCXFinalBondManagerOld
              isParentConsult={isConsult}
              listProps={armazemListProps}
              formProps={armazemFormProps}
              reducerConfig={armazemReducerConfig}
              handleAdd={handleAddArmazem}
              handleUpdate={handleUpdateArmazem}
              handleAlreadyExists={handleArmazemAlreadyExists}
              disableMultipleFields
            >
              <Grid item className={classes.gridFields} xs={12} sm={12} md={6} lg={6}>
                <QCXFinalTextField
                  id="autocomplete-select-armazem-field"
                  key="autocomplete-select-armazem-field"
                  name="ignorableFields.armazem.value"
                  label={t('com.muralis.qcx.armazem.labelSingular')}
                  initialValues={values}
                  disabled={isConsult}
                />
              </Grid>
            </QCXFinalBondManagerOld>

            {shouldShowDe(values) ? (
              <>
                <Grid item xs={12}>
                  <QCXFormSubtitle title="Declaração de Exportação Extrangeira" />
                </Grid>
                <QCXFinalBondManagerOld
                  isParentConsult={isConsult}
                  listProps={exportacaoEstrangeiraListProps}
                  formProps={exportacaoEstrangeiraFormProps}
                  reducerConfig={exportacaoEstrangeiraReducerConfig}
                  handleAdd={handleAddExportacaoExt}
                  handleUpdate={handleUpdateExportacaoExt}
                  handleAlreadyExists={handleExportacaoAlreadyExists}
                  disableMultipleFields
                >
                  <Grid item className={classes.gridFields} xs={12} sm={12} md={4} lg={4}>
                    <QCXFinalTextField
                      id="numeric-integer-de-number-field"
                      key="numeric-integer-de-number-field"
                      name="ignorableFields.de.numero"
                      label="Número da DE"
                      disabled={isConsult}
                    />
                  </Grid>
                  <Grid item className={classes.customGridFields} xs={12} sm={12} md={2} lg={2}>
                    <QCXFinalNumericIntegerField
                      id="numeric-integer-de-start-field"
                      key="numeric-integer-de-start-field"
                      name="ignorableFields.de.inicio"
                      label="Início"
                      allowNegative={false}
                      disabled={isConsult}
                    />
                  </Grid>
                  <Grid item className={classes.customGridFields} xs={12} sm={12} md={2} lg={2}>
                    <QCXFinalNumericIntegerField
                      id="numeric-integer-de-end-field"
                      key="numeric-integer-de-end-field"
                      name="ignorableFields.de.fim"
                      label="Fim"
                      allowNegative={false}
                      disabled={isConsult}
                    />
                  </Grid>
                </QCXFinalBondManagerOld>
              </>
            ) : (
              <></>
            )}
          </Grid>
        </>
      )}
    </QCXFormStepWizard>
  );
}
