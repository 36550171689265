/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import { Grid, makeStyles } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import QCXFormSubtitle from '../../components/form-title/QCXFormSubtitle';
import { selectRegimesTributacao } from '../../features/regime-tributacao/regimeTributacaoSlice';
import { selectTiposDeclaracao } from '../../features/tipo-declaracao/tipoDeclaracaoSlice';
import { selectFundamentosLegaisPisCofins } from '../../features/fundamento-legal-pis-cofins/fundamentoLegalPisCofinsSlice';
import { selectFundamentosLegaisRegimeTributacaoIi } from '../../features/fundamento-legal-regime-tributacao-ii/fundamentoLegalRegimeTributacaoIiSlice';
import QCXRenderConditionalComponent from '../../components/catalogo-produtos/QCXRenderConditionalComponent';

const useStyles = makeStyles((theme) => ({
  gridFields: {
    paddingBottom: '16px',
  },
  twin: {
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '16px',
    },
  },
  lastGridFields: {
    [theme.breakpoints.down('xs')]: {
      paddingBottom: '16px',
    },
  },
}));

export default function QCXAtributosFundamentoLegalDuimpFormGroup({ intl, modes, submitSucceeded, parentModel }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { values } = useFormState();

  const [atributosMetadados, setAtributosMetadados] = useState([]);

  const listFundamentosPisCofins = useSelector(selectFundamentosLegaisPisCofins);
  const listFundamentosIi = useSelector(selectFundamentosLegaisRegimeTributacaoIi);

  const idFundamentoLegalII = useMemo(() => values?.ii?.fundamentoLegalRegimeTributacaoIi?.id, [values]);
  const idFundamentoLegalPisCofins = useMemo(() => values?.pisCofins?.fundamentoLegal?.id, [values]);

  useEffect(() => {
    let fundamentoII = null;
    let fundamentoPisCofins = null;
    const atributosMetadadosArr = [];

    if (listFundamentosIi && Array.isArray(listFundamentosIi)) {
      fundamentoII = listFundamentosIi.find(fundamento => {
        if (fundamento?.id === idFundamentoLegalII) return true;
        return false;
      });
    }

    if (listFundamentosPisCofins && Array.isArray(listFundamentosPisCofins)) {
      fundamentoPisCofins = listFundamentosPisCofins.find(fundamento => {
        if (fundamento?.id === idFundamentoLegalPisCofins) return true;
        return false;
      });
    }

    if (fundamentoII && fundamentoPisCofins && fundamentoII?.code === fundamentoPisCofins?.code) {
      // mesmos fundamentos
      if (fundamentoII.listaAtributosMetadados && Array.isArray(fundamentoII.listaAtributosMetadados)) {
        fundamentoII.listaAtributosMetadados.forEach(atributo => atributosMetadadosArr.push(atributo))
      }
    } else {
      if (fundamentoII && fundamentoII.listaAtributosMetadados && Array.isArray(fundamentoII.listaAtributosMetadados)) {
        fundamentoII.listaAtributosMetadados.forEach(atributo => atributosMetadadosArr.push(atributo))
      }

      if (fundamentoPisCofins && fundamentoPisCofins.listaAtributosMetadados && Array.isArray(fundamentoPisCofins.listaAtributosMetadados)) {
        fundamentoPisCofins.listaAtributosMetadados.forEach(atributo => atributosMetadadosArr.push(atributo))
      }
    }
 
    setAtributosMetadados([...atributosMetadadosArr])

  }, [idFundamentoLegalII, idFundamentoLegalPisCofins, listFundamentosIi, listFundamentosPisCofins]);


  const { isConsult, isSubNone, isSubConsult } = modes;

  return (
    <Grid container>

      <Grid container spacing={2} style={{ marginTop: '1rem' }}>
        {atributosMetadados != null && Array.isArray(atributosMetadados) && atributosMetadados.length ? (

            atributosMetadados.map((atributo) => (
              <QCXRenderConditionalComponent
                atributo={atributo} 
                disabled={isConsult} 
                isConsult={false}
                codObjetivo='9'
                basePath="atributosFundamentoLegalDuimp"
              />
            )
          )
        ) : (
          <Grid item>Nenhum atributo de fundamento legal disponível para esse fundamento selecionado !!</Grid>
        )}
      </Grid>
    </Grid>
  );
}
