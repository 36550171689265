import { Box, Grid } from '@material-ui/core';
import { useCallback, useMemo } from 'react';
import { useForm } from 'react-final-form';
import { v4 as uuid } from 'uuid';
import QCXFinalBondManager from '../../shared-components/final-gerenciador-relacionamento/QCXFinalBondManager';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXSelectOrgaoLPCO from '../../shared-components/orgao-lpco/QCXSelectOrgaoLPCO';
import { isStrictEquals } from '../../utils/general/general-utils';

export default function QCXMercadoriaLPCOBondManager({ isConsult = false, listRootName = 'listaMercadoriaLPCO' }) {
  const form = useForm();

  const handleAdd = useCallback((currentVinculo, currentVinculos, handleSuccessAddVinculo) => {
    const novoVinculo = {
      id: uuid(),
      orgao: currentVinculo.orgao,
      nomeLpco: currentVinculo.nomeLpco,
    };

    const updatedVinculos = [...currentVinculos, novoVinculo];

    handleSuccessAddVinculo(updatedVinculos);
  }, []);

  const handleUpdate = useCallback((currentVinculo, currentVinculos, handleSuccessUpdateVinculo) => {
    const updatedVinculos =
      currentVinculos?.map((existingVinculo) =>
        isStrictEquals(existingVinculo?.id, currentVinculo?.id) ? currentVinculo : existingVinculo
      ) || currentVinculos;

    handleSuccessUpdateVinculo(updatedVinculos);
  }, []);

  const handleAlreadyExists = useCallback(
    (currentVinculo, currentVinculosList) =>
      currentVinculosList.some(
        (vinculo) =>
          isStrictEquals(vinculo?.orgao, currentVinculo?.orgao) &&
          isStrictEquals(vinculo?.nomeLpco, currentVinculo?.nomeLpco)
      ),
    []
  );

  const listProps = useMemo(
    () => ({
      name: listRootName,
      columns: [
        {
          field: 'orgao',
          headerName: 'Órgão',
          flex: 200,
          valueGetter: ({ row }) => row?.orgao?.code ?? '-',
        },
        {
          field: 'nome',
          headerName: 'Nome LPCO',
          flex: 200,
          valueGetter: ({ row }) => row?.nomeLpco ?? '-',
        },
      ],
    }),
    []
  );

  const formProps = useMemo(
    () => ({
      rootName: 'ignorableFields.mercadoriaLpcoBond',
      fields: [
        {
          name: 'orgao',
          label: 'Órgão',
        },
        {
          name: 'nomeLpco',
          label: 'Nome LPCO',
        },
      ],
    }),
    []
  );

  const handleOrgaoChange = (e, c) => {
    const [code, description] = c?.props?.children?.split('-');

    form.change('ignorableFields.mercadoriaLpcoBond.orgao', {
      id: e.target.value,
      code: code?.trim(),
      description: description?.trim(),
    });
  };

  return (
    <Box style={{ pointerEvents: isConsult ? 'none' : '' }}>
      <QCXFinalBondManager
        isParentConsult={isConsult}
        listProps={listProps}
        formProps={formProps}
        controlButtonsGridProps={{
          add: {
            xs: 12,
            sm: 2,
            md: 2,
          },
          update: {
            xs: 6,
            sm: 6,
            md: 1,
          },
          clear: {
            xs: 6,
            sm: 6,
            md: 1,
          },
        }}
        handleAdd={handleAdd}
        handleUpdate={handleUpdate}
        handleAlreadyExists={handleAlreadyExists}
      >
        <>
          <Grid item sm={4}>
            <QCXSelectOrgaoLPCO
              id="mercadoria-lpco-orgao"
              key="mercadoria-lpco-orgao"
              name="ignorableFields.mercadoriaLpcoBond.orgao"
              label="Órgão"
              disabled={isConsult}
              onChange={handleOrgaoChange}
            />
          </Grid>
          <Grid item sm={4}>
            <QCXFinalTextField
              id="mercadoria-lpco-nomeLpco"
              key="mercadoria-lpco-nomeLpco"
              name="ignorableFields.mercadoriaLpcoBond.nomeLpco"
              label="Nome LPCO"
              disabled={isConsult}
            />
          </Grid>
        </>
      </QCXFinalBondManager>
    </Box>
  );
}
