import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _ from 'lodash';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { getOptionByValueInList } from '../../utils/general/general-utils';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';
import i18n from '../../i18n';

const listAplicacoes = [
  {
    label: i18n.t('com.muralis.qcx.consumo').toUpperCase(),
    value: 'CONSUMO',
  },
  {
    label: i18n.t('com.muralis.qcx.revenda').toUpperCase(),
    value: 'REVENDA',
  },
];

const listAplicacoesDuimp = [
  {
    label: 'Incorporação ao Ativo Fixo'.toUpperCase(),
    value: 'INCORPORACAO_ATIVO_FIXO',
  },
  {
    label: 'Industrialização'.toUpperCase(),
    value: 'INDUSTRIALIZACAO',
  },
  {
    label: 'Outra'.toUpperCase(),
    value: 'OUTRA',
  },
];

const list = listAplicacoes.concat(listAplicacoesDuimp);

export default function QCXFinalSelectAplicacaoMercadoriaAutocomplete({
  label,
  name,
  initialValues,
  isDuimp = false,
  ...restProps
}) {
  const resolvedList = useMemo(() => {
    let aplicacoes = listAplicacoes;

    if (isDuimp) {
      aplicacoes = aplicacoes.concat(listAplicacoesDuimp);
    }

    return aplicacoes;
  }, [isDuimp]);

  const [defaultValue, setDefaultValue] = useState('');

  useEffect(() => {
    const defaultInitialValue = _.get(initialValues, name);

    const option = resolvedList.find((item) => item?.value === defaultInitialValue);

    if (option) {
      setDefaultValue(option);
    } else {
      setDefaultValue('');
    }
  }, [resolvedList, initialValues]);

  const filterOptions = useMemo(
    () =>
      createFilterOptions({
        stringify: (option) => option.label + option.value,
      }),
    []
  );

  const getOptionLabel = useCallback((item) => item?.label || '', []);

  const getOptionValue = useCallback((item) => item?.value || '', []);

  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={resolvedList}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      {...restProps}
    />
  );
}

function getAplicacaoMercadoriaLabelByValue(value) {
  const foundOption = getOptionByValueInList(list, value);
  return foundOption && foundOption?.label;
}

export { getAplicacaoMercadoriaLabelByValue };
