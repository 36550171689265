import { doGetQCXRequest } from "../../../utils/api/domain/domain-api-utils";
import { IDiagnostico } from "../../pages/importacao/declaracaoImportacao/components/TransmissaoRenderDados/transmissaoRenderDadosDi";

export interface fetchDataDiagnosticoResponse {
    message: string | null;
    data?: IDiagnostico | null;
}

async function fetchDataDiagnostico(id: number): Promise<fetchDataDiagnosticoResponse> {
    const url: string =
        process.env.REACT_APP_QCX_API_DECLARACAO_IMPORTACAO_ENDPOINT!
        + '/resultado-transmissao/di'

    let data: IDiagnostico | null = null;
    let message: string | null = null;

    try {
        const response = await doGetQCXRequest([url, id]);
        data = response.data;

    } catch (error: unknown) {

        if (error instanceof Error &&
            (error.message.includes('404') || error.message.includes('500'))) {
                const errorResponse = (error as any).response?.data;

            message = errorResponse ? errorResponse : 'com.muralis.qcx.extracaoDiagnostico.erroDeclaracaoNaoAtendeAosRequisitos';
        } else if (error instanceof Error && error.message.includes('401')) {
            message = 'com.muralis.qcx.extracaoDiagnostico.erro401';
        } else {
            message = 'com.muralis.qcx.extracaoDiagnostico.erroInesperado';
        }
    }

    return {
        message: message,
        data: data
    }
}

async function fetchCriaDiagnostico(id: number, tipo: string): Promise<any> {

    const url: string = process.env.REACT_APP_QCX_API_DECLARACAO_IMPORTACAO_ENDPOINT!
        + `/xml-transmissao-diagnostico/di/${id}?TIPO=${tipo}`

    try {
        const response = await doGetQCXRequest([url]);;
        return response;

    } catch (error: any) {
        return error.response;
    }

}

async function fetchTransmissaoDuimp(id: number, tipo: string, transmitirComErros: boolean): Promise<any> {

    let url: string = process.env.REACT_APP_QCX_API_DECLARACAO_IMPORTACAO_ENDPOINT! + `/transmissao-duimp/${id}?TIPO=${tipo}`
    
    if (transmitirComErros != null && transmitirComErros != undefined) {
        url += `&transmitir-erros=${transmitirComErros}`; 
    }

    try {
        const response = await doGetQCXRequest([url]);;
        return response;

    } catch (error: any) {
        return error.response;
    }

}

async function fetchRegistraDuimp(id: number, tipo: string, transmitirComErros: boolean): Promise<any> {

    let url: string = process.env.REACT_APP_QCX_API_DECLARACAO_IMPORTACAO_ENDPOINT! + `/solicitar-registro/${id}?TIPO=${tipo}`
    
    if (transmitirComErros != null && transmitirComErros != undefined) {
        url += `&transmitir-erros=${transmitirComErros}`; 
    }

    try {
        const response = await doGetQCXRequest([url]);;
        return response;

    } catch (error: any) {
        return error.response;
    }

}


async function fetchRelatorioTransmissaoDuimp(id: number): Promise<any> {

    const url: string = process.env.REACT_APP_QCX_API_DECLARACAO_IMPORTACAO_ENDPOINT! + `/relatorio-duimp/${id}`

    try {
        const response = await doGetQCXRequest([url]);;
        return response;

    } catch (error: any) {
        return error.response;
    }

}

async function generateReportDiagnosticoDI(id: number): Promise<any> {
    const queryParams = [{ name: 'TIPO', value: 'DIAGNOSTICO' }]

    return doGetQCXRequest(['diduimp', 'xml-report', 'di', id], queryParams);
}

async function generateReportRegistroDI(id: number): Promise<any> {
    const queryParams = [{ name: 'TIPO', value: 'REGISTRO' }]

    return doGetQCXRequest(['diduimp', 'xml-report', 'di', id], queryParams);
}

export { fetchCriaDiagnostico, fetchDataDiagnostico, generateReportDiagnosticoDI, generateReportRegistroDI, fetchTransmissaoDuimp, fetchRelatorioTransmissaoDuimp, fetchRegistraDuimp };


