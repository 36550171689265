/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import { Box, Grid, makeStyles } from '@material-ui/core';
import _, { debounce, forEach, isArray, isEmpty, isFunction, values } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid, validate } from 'uuid';
import QCXButton from '../../shared-components/button/QCXButton';
import {
  selectCatalogosProdutos,
  selectRelatedEspecificacaoModel,
} from '../../features/catalogo-produtos/catalogoProdutosSlice';
import {
  selectRelatedAdicaoModel,
  selectRelatedItemModel,
} from '../../features/declaracao-importacao/declaracaoImportacaoSelectors';
import {
  changeToSubConsultMode,
  changeToSubCreateMode,
  failure,
  loading,
  resetBackgroundMode,
  resetRelatedItemModel,
  setRelatedItemModel,
  setResponse,
  success,
} from '../../features/declaracao-importacao/declaracaoImportacaoSlice';
import {
  fetchAdicoesByDiDuimpIdAsync,
  fetchByIdFromFaturaAsync,
  updateMercadoriaAsync,
} from '../../features/declaracao-importacao/declaracaoImportacaoThunks';
import { setErrorFeedback, setSuccessFeedback } from '../../features/feedback/feedbackSlice';
import { ncmSubitemActions } from '../../features/ncm-subitem/ncmSubitemSlice';
import { nveAtributoActions } from '../../features/nve-atributo/nveAtributoSlice';
import { nveEspecificacaoActions } from '../../features/nve-especificacao/nveEspecificacaoSlice';
import { nveActions } from '../../features/nve/nveSlice';
import { selectTiposDeclaracao } from '../../features/tipo-declaracao/tipoDeclaracaoSlice';
import { unidadeDeMedidaActions } from '../../features/unidade-medida/unidadeDeMedidaSlice';
import QCXConfirmDialog from '../../shared-components/dialog/QCXConfirmDialog';
import QCXFormStepWizard from '../../shared-components/form-step-wizard/QCXFormStepWizard';
import { getTipoCalculoValue } from '../../shared-components/select-forma-calculo/formaCalculoUtils';
import {
  isRelacaoFornecedorFabricanteDesconhecido,
  isRelacaoFornecedorFabricanteExportador,
  isRelacaoFornecedorFabricanteNaoExportador,
} from '../../shared-components/select-relacao-fornecedor/relacaoFornecedorUtils';
import {
  TIPO_DECLARACAO_DUIMP,
  configureGeneralTipoDeclaracaoMatcherWith,
} from '../../shared-components/select-tipo-declaracao/tipoDeclaracaoUtils';
import QCXCustomRegistrationTabControlTemplate from '../../templates/custom-registration-one-to-many-page/QCXCustomRegistrationTabControlTemplate';
import useCatalogoProdutosAtributosListeners from '../../utils/general/catalogo-produtos/catalogoProdutosAtributosListeners';
import useMercadoriaListenersGroup from '../../utils/general/fatura/faturaMercadoriaListeners';
import { TIPO_DI_DUIMP, TIPO_DUIMP } from '../../utils/general/fatura/faturaUtils';
import { isValid, unnormalizeNumeral } from '../../utils/general/general-utils';
import { formatBrazilianNumericDecimal } from '../../utils/hooks/form/field/formatters';
import useExternallyFilling from '../../utils/hooks/form/field/useExternallyFilling';
import useOperationMiddleware from '../../utils/hooks/operation/middleware/useOperationMiddleware';
import QCXFaturaAdicionaisFormGroup from '../fatura/QCXFaturaAdicionaisFormGroup';
import QCXFaturaMercadoriaStandardFormGroup from '../fatura/QCXFaturaMercadoriaStandardFormGroup';
import { useMercadoriaUtils } from '../fatura/mercadoriaUtils';
import QCXFormSubtitle from '../form-title/QCXFormSubtitle';
import QCXFinalAtributoNveBondManager from './QCXFinalAtributoNveBondManager';
import { useFornecedorListenersGroup } from '../relacao-fornecedor/relacao-fornecedor-utils';
import QCXFinalLotesBondManager from '../fatura/FaturaMercadoriaForm/QCXFinalLotesBondManager';
import QCXFaturaAtributosNcmDinamico from '../fatura/QCXFaturaAtributosNcmDinamico';
import RepassarAtributosNVEItens from './repassarAtributosNVE/RepassarAtributosNVEItens';
import TableCellWithTooltip from '../../shared-components/tooltip-cell/TableCellWithTooltip';
import QCXMercadoriaLPCOBondManager from './QCXMercadoriaLPCOBondManager';
import QCXMercadoriaDeclaracaoVinculadaBondManager from './QCXMercadoriaDeclaracaoVinculadaBondManager';

const useStyles = makeStyles((theme) => ({
  gridFields: {
    paddingBottom: '16px',
  },
  subtitle: {
    paddingTop: '16px !important',
  },
  destaqueNcmFieldLeft: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: '16px',
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: '16px',
    },
  },
  // FIXME: Fake field
  hiddenFakeFields: {
    width: '0px',
    height: '0px',
    visibility: 'hidden',
    zIndex: -9999,
    position: 'absolute',
  },
}));

export default function QCXDeclaracaoImportacaoItensForm({
  isIdle,
  isLoading,
  isPreparingAction,
  isCreate,
  isConsult,
  isUpdate,
  isSubNone,
  isSubCreate,
  isSubConsult,
  isSubUpdate,
  isBackgroundDelete,
  isSubBackgroundCreate,
  isSubBackgroundConsult,
  isSubBackgroundUpdate,
  isSubBackgroundDelete,
  parentModel,
  defaultItem,
  handleCustomSaveBy,
  handleChangeToConsult,
  handleChangeToUpdate,
  handleChangeToSubNone,
  handleChangeToSubCreate,
  handleChangeToSubConsult,
  handleChangeToSubUpdate,
  handleChangeToBackgroundDelete,
  handleCancelUpdate,
  handleMetadataUpdate,
  handleLoadingStatus,
  handleResetStatus,
  controlComponentProps,
  tabManagerProps = {},
  formPaginationControlProps = {},
  authInfo = {},
  ...restProps
}) {
  const { t } = useTranslation();

  const classes = useStyles();

  const dispatch = useDispatch();

  const hasFetchedMercadoriasRef = useRef(false);

  const itemModel = useSelector(selectRelatedItemModel);
  const adicaoModel = useSelector(selectRelatedAdicaoModel);
  const nveEspecificacao = useSelector(selectRelatedEspecificacaoModel);
  const unidadeMedidaList = useSelector(unidadeDeMedidaActions.selectUnidadesdeMedida);
  const catalogoProdutoList = useSelector(selectCatalogosProdutos);
  const ncmList = useSelector(ncmSubitemActions.selectNcmSubitens);
  const tiposDeclaracao = useSelector(selectTiposDeclaracao);

  const [isFilling, subscribeToFilling] = useExternallyFilling();
  const mercadoriaUtils = useMercadoriaUtils();

  const [isFirstFormLoadValues, setIsFirstFormLoadValues] = useState(true);

  const mercadorias = useMemo(() => parentModel?.mercadorias, [parentModel]);

  const idFatura = useMemo(() => parentModel?.id, [parentModel]);
  const idDIDUIMP = useMemo(() => parentModel?.atributosAdicionais?.id, [parentModel]);
  const isDIDUIMP = useMemo(() => parentModel?.atributosAdicionais?.tipoDeclaracao?.code === '50', [parentModel]);

  const adicoes = useMemo(() => parentModel?.atributosAdicionais?.adicoes, [parentModel]);

  const isDadosEmComumFornecedor = useMemo(() => parentModel?.dadosComumFornecedor, [parentModel]);

  const isSobrescreveDadosPartnumber = useMemo(() => parentModel?.sobrescreveDadosPartnumber, [parentModel]);

  const isFillCommonDataMode = useMemo(
    () => isSubCreate && isDadosEmComumFornecedor,
    [isSubCreate, isDadosEmComumFornecedor]
  );

  const hasItems = useMemo(() => isArray(mercadorias) && !isEmpty(mercadorias), [mercadorias]);

  const tipoDeclaracao = useMemo(() => parentModel?.atributosAdicionais?.tipoDeclaracao?.id, [parentModel]);

  const [isRepasseNVEDialogOpen, setIsRepasseNVEDialogOpen] = useState(false);
  const [showRepasseNVEButton, setShowRepasseNVEButton] = useState(true);

  useEffect(() => {
    if (mercadorias?.length === 0 && !hasFetchedMercadoriasRef.current) {
      hasFetchedMercadoriasRef.current = true;
      dispatch(fetchAdicoesByDiDuimpIdAsync(idDIDUIMP));
      dispatch(ncmSubitemActions.resetModel());
      dispatch(resetRelatedItemModel());
    }
  }, [idFatura, idDIDUIMP, mercadorias]);

  const handleTabChange = (tabIndex) => setShowRepasseNVEButton(tabIndex === 0);

  const normalize = useCallback(
    (unnormalizedData) => {
      const { atributos, ...restUnnormalizedData } = unnormalizedData;

      const normalizedData = mercadoriaUtils.normalize(restUnnormalizedData);
      const normalizedAtributos = (atributos || []).map((atributo) =>
        validate(atributo?.id)
          ? {
              ...atributo,
              id: null,
            }
          : atributo
      );

      return {
        ...normalizedData,
        atributos: normalizedAtributos,
        listaMercadoriaLPCO:
          unnormalizedData?.listaMercadoriaLPCO?.map((lpco) => ({
            ...lpco,
            id: validate(lpco.id) ? 0 : lpco.id,
          })) ?? [],
        listaMercadoriaDeclaracaoVinculada:
          unnormalizedData?.listaMercadoriaDeclaracaoVinculada?.map((declaracao) => ({
            ...declaracao,
            id: validate(declaracao.id) ? 0 : declaracao.id,
          })) ?? [],
      };
    },
    [mercadoriaUtils]
  );

  const unnormalize = useCallback(
    (normalizedData) => {
      const { ...restNormalizedData } = normalizedData;

      const unnormalizedData = mercadoriaUtils.unnormalize(restNormalizedData);
      const unnormalizedAtributos = (normalizedData?.atributos || [])?.map((atributo) =>
        atributo?.id
          ? {
              ...atributo,
              nve: atributo?.nve?.id ? atributo?.nve : undefined,
              atributo: atributo?.atributo?.id ? atributo?.atributo : undefined,
              especificacao: atributo?.especificacao?.id ? atributo?.especificacao : undefined,
            }
          : {
              ...atributo,
              nve: atributo?.nve?.id ? atributo?.nve : undefined,
              atributo: atributo?.atributo?.id ? atributo?.atributo : undefined,
              especificacao: atributo?.especificacao?.id ? atributo?.especificacao : undefined,
              id: uuid(),
            }
      );

      return {
        ...unnormalizedData,
        atributos: unnormalizedAtributos,
      };
    },
    [mercadoriaUtils]
  );

  const initialValues = useMemo(
    () =>
      !isSubCreate
        ? unnormalize(itemModel)
        : {
            quantidade: '',
            valorTotalMoeda: '',
            icms: '',
            destaqueNcm: '',
            pesoLiquido: '',
            pesoLiquidoUnitario: '',
            quantidadeEstatistica: '',
            percentualDesconto: '',
            valorUnitarioMoeda: '',
            meta: {
              initialized: false,
            },
          },
    [isSubCreate, itemModel, unnormalize]
  );

  const suggestedInitialValues = useMemo(
    () =>
      isFillCommonDataMode
        ? {
            partnumberAutocompleteSelector: initialValues?.partnumber,
            partNumberSelector: initialValues?.partnumber,
            relacao: parentModel?.relacao,
            exportador: parentModel?.exportador?.id
              ? {
                  id: parentModel?.exportador?.id,
                }
              : undefined,
            fabricante: parentModel?.fabricante?.id
              ? {
                  id: parentModel?.fabricante?.id,
                }
              : undefined,
            paisOrigem: parentModel?.paisOrigem?.id
              ? {
                  id: parentModel?.paisOrigem?.id,
                }
              : undefined,
          }
        : {},
    [isFillCommonDataMode, initialValues, parentModel]
  );

  const computedInitialValues = useMemo(
    () => ({
      ...initialValues,
      ...suggestedInitialValues,
    }),
    [initialValues, suggestedInitialValues]
  );

  const handleChangeModel = useCallback((updatedModel) => {
    dispatch(setRelatedItemModel(updatedModel));
  }, []);

  const handleNew = useCallback(
    (additional = {}) => {
      const additionalCallback = () => {
        if (!isEmpty(additional) && isFunction(additional?.init)) {
          additional.init();
        }

        handleChangeToSubCreate();
      };

      if (!isCreate) {
        handleChangeToUpdate({
          callback: additionalCallback,
        });

        dispatch(resetRelatedItemModel());

        return;
      }

      additionalCallback.call();
    },
    [isCreate, handleChangeToUpdate, handleChangeToSubCreate]
  );

  const handleCancelCreate = useCallback(
    (additional = {}) => {
      const additionalCallback = () => {
        if (!isEmpty(additional) && isFunction(additional?.callback)) {
          additional.callback();
        }
      };

      if (!isCreate) {
        handleCancelUpdate({
          callback: additionalCallback,
        });

        return;
      }

      additionalCallback.call();
    },
    [isCreate, handleCancelUpdate, handleChangeToSubConsult]
  );

  const handleEdit = useCallback(() => {
    if (!isCreate) {
      handleChangeToUpdate({
        callback: handleChangeToSubUpdate,
      });

      return;
    }

    handleChangeToSubUpdate();
  }, [isCreate, handleChangeToUpdate, handleChangeToSubUpdate]);

  const handleInternalCancelUpdate = useCallback(() => {
    if (isUpdate) {
      handleCancelUpdate({
        callback: handleChangeToSubConsult,
      });

      return;
    }

    handleChangeToSubConsult();
  }, [isUpdate, handleCancelUpdate, handleChangeToSubConsult]);

  const handleClearForm = useCallback(() => {
    dispatch(ncmSubitemActions.resetModel());
    dispatch(resetRelatedItemModel());
  }, []);

  const isSomeTipoDeclaracaoBy = useCallback(
    (codesToCompare) => configureGeneralTipoDeclaracaoMatcherWith(tiposDeclaracao)(tipoDeclaracao, codesToCompare),
    [tiposDeclaracao, tipoDeclaracao]
  );

  const isTipoDeclaracaoDuimp = useMemo(
    () => isSomeTipoDeclaracaoBy([TIPO_DECLARACAO_DUIMP]),
    [isSomeTipoDeclaracaoBy]
  );

  const handleRemoveById = useCallback(
    async (data, refreshCallback) => {
      const composeWith = (parent) => {
        dispatch(resetRelatedItemModel(data));

        return {
          ...parent,
          mercadorias: [...(parent?.mercadorias?.filter((current) => current?.id !== data?.id) || [])],
        };
      };

      const executeDebounced = debounce(async () => {
        try {
          const response = await handleCustomSaveBy({
            composeWith,
            step: 7,
          });

          if (response?.status === 200) {
            const handleResultWithDebounce = debounce(() => {
              dispatch(success());

              const saved = response?.data;

              const processo = saved?.atributosAdicionais?.processo;

              const itemNumber = data?.metadata?.item?.numero;
              const adicaoNumber = data?.metadata?.parent?.numero;

              const feedbackMessage = t('com.muralis.qcx.mensagem.itemRemovidoListaAdicao', {
                item: itemNumber,
                adicaoNumero: adicaoNumber,
                processo,
              });

              dispatch(
                setSuccessFeedback({
                  message: feedbackMessage,
                })
              );

              dispatch(fetchByIdFromFaturaAsync(saved?.id));

              if (isFunction(refreshCallback)) {
                refreshCallback(saved?.mercadorias);
              }
            }, 500);

            handleResultWithDebounce();
          }
        } catch ({ request, response }) {
          dispatch(failure());
          dispatch(
            setErrorFeedback({
              message: t('com.muralis.qcx.erro.erroRemoverItemListaItensDeclaracaoImportacao', {
                item: data?.metadata?.item?.numero,
                processo: request?.data?.atributosAdicionais?.processo,
                mensagem: response?.data?.message,
              }),
            })
          );
        }
      }, 500);

      dispatch(loading());
      executeDebounced();
    },
    [handleCustomSaveBy]
  );

  const [handleRemoveByIdMiddleware, operationOfRemoveById] = useOperationMiddleware(handleRemoveById, [
    handleCustomSaveBy,
  ]);

  const handleRemoveByForm = useCallback(
    (refreshCallback) => {
      handleChangeToBackgroundDelete();

      const configureOperation = () => ({
        options: {
          title: t('com.muralis.qcx.acoes.confirmarOperacaoRemocao').toUpperCase(),
          message: t('com.muralis.qcx.acoes.operacaoRemocaoItemListaAdicao', {
            item: itemModel?.metadata?.item?.numero,
            itemParente: itemModel?.metadata?.parent?.numero,
          }),
          renderMiddleMessage: () => t('com.muralis.qcx.alerta.itemProvenienteTransferencia'),
          endMessage: t('com.muralis.qcx.alerta.remocao'),
        },
        cleanUp: () => {
          dispatch(resetBackgroundMode());
        },
      });

      handleRemoveByIdMiddleware(configureOperation, itemModel, refreshCallback);
    },
    [itemModel, handleChangeToBackgroundDelete, handleRemoveByIdMiddleware]
  );

  const handleRemoveByTable = useCallback(
    ({ row }) => {
      handleChangeToBackgroundDelete();

      const configureOperation = () => ({
        options: {
          title: t('com.muralis.qcx.acoes.confirmarOperacaoRemocao').toUpperCase(),
          message: t('com.muralis.qcx.acoes.operacaoRemocaoItemListaAdicao', {
            item: row?.metadata?.item?.numero,
            itemParente: row?.metadata?.parent?.numero,
          }),
          renderMiddleMessage: () => t('com.muralis.qcx.alerta.itemProvenienteTransferencia'),
          endMessage: t('com.muralis.qcx.alerta.remocao'),
        },
        cleanUp: () => {
          dispatch(resetBackgroundMode());
        },
      });

      handleRemoveByIdMiddleware(configureOperation, row);
    },
    [handleChangeToBackgroundDelete, handleRemoveByIdMiddleware]
  );

  const validateFormValues = useCallback((valuess, form) => {
    window.finalForm = form;
    if (form.hasSubmitErrors || form.hasValidationErrors) {
      return true;
    }

    const requiredFields = [
      'relacao',
      'exportador.id',
      'fabricante.id',
      'paisOrigem.id',
      'quantidade',
      'unidadeDeMedida.id',
      'valorUnitarioMoeda',
      'ncm.id',
      'adicionais.especificacao',
    ];

    const validableFields = ['adicionais.anoFabricacao'];

    const currentRelacaoFornecedor = valuess?.relacao;
    const relacaoFabricanteExportador = isRelacaoFornecedorFabricanteExportador(currentRelacaoFornecedor);
    const relacaoFabricanteNaoExportador = isRelacaoFornecedorFabricanteNaoExportador(currentRelacaoFornecedor);
    const relacaoFabricanteDesconhecido = isRelacaoFornecedorFabricanteDesconhecido(currentRelacaoFornecedor);

    const ignoreCaseBy = (field) => {
      if ((relacaoFabricanteExportador || relacaoFabricanteNaoExportador) && field === 'paisOrigem.id') {
        return true;
      }

      if (relacaoFabricanteDesconhecido && field === 'fabricante.id') {
        return true;
      }

      if (relacaoFabricanteExportador && field === 'fabricante.id') {
        return true;
      }

      return false;
    };

    const hasNonFilledFields = requiredFields.some((requiredField) => {
      if (ignoreCaseBy(requiredField)) {
        return false;
      }

      const value = _.get(valuess, requiredField);

      const invalid = !isValid(value) || (_.isString(value) && isEmpty(value));

      return invalid;
    });

    const formState = form.getState();

    const hasInvalidFields = validableFields.some((validableField) => _.has(formState?.errors, validableField));

    return hasNonFilledFields || hasInvalidFields;
  }, []);

  const defaultRestartedValues = useMemo(
    () => ({
      partnumberAutocompleteSelector: '',
      partNumberSelector: '',
      partnumber: '',
      descricaoResumida: '',
      pesoLiquido: '',
      pesoLiquidoUnitario: '',
      valorUnitarioMoeda: '',
      destaqueNcm: '',
      adicionais: {
        numeroSerie: '',
        marca: '',
        modelo: '',
        dataValidade: '',
        anoFabricacao: '',
        lote: '',
        especificacao: '',
      },
      quantidadeEstatistica: '',
      quantidade: '',
      valorTotalMoeda: '',
      percentualDesconto: '',
      valorTotalMcvMoeda: '',
      tipoCalculo: 'QUANTIDADE_X_VALOR',
      icms: '',
    }),
    []
  );

  const handleSubmitLegacy = useCallback(
    async (data, form) => {
      if (!isRepasseNVEDialogOpen && validateFormValues(data, form)) {
        dispatch(failure());
        dispatch(
          setErrorFeedback({
            message: t('com.muralis.qcx.validacao.camposInvalidosObrigatoriosNaoPreenchidos'),
          })
        );

        return;
      }
      let composeWith = null;
      /**
       * Roda caso o modal de repasse de NVE esteja aberto
       * Verifica data.shouldOverwrite para saber se deve sobrescrever os atributos ou adicionar os novos apenas
       * Não podem existir atributos diferentes com o mesmo atributos[i].atributo.id
       * Pega os dados de data.ignorableFields.atributos e adiciona no objeto da mercadoria
       */
      const listaMercadorias = [];
      if (isRepasseNVEDialogOpen) {
        forEach(data?.meta?.selected, (item) => {
          const mercadoria = normalize(item);

          let updatedAtributos = [];

          if (data.shouldOverwrite) {
            updatedAtributos = (data?.ignorableFields?.atributos || []).map((atributo) => ({
              ...atributo,
              id: null,
            }));
          } else {
            const existingAtributos = mercadoria.atributos || [];

            const existingAtributosMap = existingAtributos.reduce((acc, atributo) => {
              acc[atributo.atributo.id] = atributo;
              return acc;
            }, {});

            data?.ignorableFields?.atributos.forEach((newAtributo) => {
              const newAtributoId = newAtributo.atributo.id;
              if (existingAtributosMap[newAtributoId]) {
                existingAtributosMap[newAtributoId] = { ...newAtributo, id: null };
              } else {
                existingAtributosMap[newAtributoId] = { ...newAtributo, id: null };
              }
            });
            updatedAtributos = Object.values(existingAtributosMap);
          }

          listaMercadorias.push({
            ...mercadoria,
            atributos: updatedAtributos,
          });
        });
        composeWith = (parent) => ({
          ...parent,
          mercadorias: parent?.mercadorias.map((mercadoriaParent) => {
            const matchingMerc = listaMercadorias.find((merc) => merc.id === mercadoriaParent.id);
            return matchingMerc ?? mercadoriaParent;
          }),
        });
      } else {
        const normalizedItem = normalize(data);

        composeWith = (parent) => {
          dispatch(setRelatedItemModel(normalizedItem));

          if (isSubCreate) {
            return {
              ...parent,
              mercadorias: [
                ...parent?.mercadorias,
                {
                  ...normalizedItem,
                  id: null,
                },
              ],
            };
          }

          return {
            ...parent,
            mercadorias: [
              ...(parent?.mercadorias.map((current) => (current?.id === data?.id ? normalizedItem : current)) || []),
            ],
          };
        };
      }

      const executeDebounced = debounce(async () => {
        setIsRepasseNVEDialogOpen(false);
        try {
          const response = await handleCustomSaveBy({
            composeWith,
            step: 7,
          });

          if (response?.status === 200) {
            const handleResultWithDebounce = debounce(() => {
              dispatch(success());

              const saved = response?.data;

              const processo = saved?.atributosAdicionais?.processo;
              const adicaoNumber = data?.metadata?.parent?.numero;

              const itemNumber = isSubCreate ? saved?.mercadorias?.length : data?.item;

              const feedbackMessage = isSubCreate
                ? t('com.muralis.qcx.mensagem.itemAdicionadoListaItensDeclaracaoImportacao', {
                    item: itemNumber,
                    processo,
                  })
                : t('com.muralis.qcx.mensagem.itemSalvoListaItensDeclaracaoImportacao', {
                    item: data?.metadata?.item?.numero,
                    adicaoNumero: adicaoNumber,
                    processo,
                  });

              dispatch(
                setResponse({
                  status: response.status,
                  data: saved,
                  message: feedbackMessage,
                })
              );

              if (isUpdate && !isSubCreate) {
                handleChangeToConsult();
              }

              if (isSubUpdate) {
                dispatch(changeToSubConsultMode());
              }

              if (isSubCreate) {
                form.reset(defaultRestartedValues);
                form.restart(computedInitialValues);

                dispatch(resetRelatedItemModel());
                dispatch(changeToSubCreateMode());
              }

              dispatch(fetchByIdFromFaturaAsync(saved?.id));
            }, 500);
            dispatch(setSuccessFeedback({ message: 'Fatura Salva' }));
            handleResultWithDebounce();
          }
        } catch ({ request, response }) {
          const message = isSubCreate
            ? t('com.muralis.qcx.erro.erroAdicionarItemListaDeclaracaoImportacao', {
                processo: parentModel?.atributosAdicionais?.processo,
                mensagem: response?.data?.message,
              })
            : t('com.muralis.qcx.erro.erroSalvarItemListaDeclaracaoImportacao', {
                processo: parentModel?.atributosAdicionais?.processo,
                mensagem: response?.data?.message,
                item: data?.metadata?.item?.numero,
              });

          dispatch(failure());
          dispatch(
            setErrorFeedback({
              message,
            })
          );
        }
      }, 500);

      dispatch(loading());
      executeDebounced();
    },
    [
      isUpdate,
      isSubCreate,
      isSubUpdate,
      normalize,
      parentModel,
      defaultRestartedValues,
      validateFormValues,
      handleCustomSaveBy,
      handleChangeToConsult,
      isRepasseNVEDialogOpen,
    ]
  );

  const handleSubmitNew = useCallback(
    async (data, form) => {
      console.log(data);

      const normalizedMercadoria = normalize(data);

      dispatch(updateMercadoriaAsync({ ...normalizedMercadoria, idFatura }));

      // TODO:
      // dispatch(loading());
      // dispatch(success());

      // // sucesso feedback
      // const adicaoNumber = data?.metadata?.parent?.numero;

      // const itemNumber = isSubCreate ? saved?.mercadorias?.length : data?.item;

      // const feedbackMessage = isSubCreate
      //   ? t('com.muralis.qcx.mensagem.itemAdicionadoListaItensDeclaracaoImportacao', {
      //       item: itemNumber,
      //       processo,
      //     })
      //   : t('com.muralis.qcx.mensagem.itemSalvoListaItensDeclaracaoImportacao', {
      //       item: data?.metadata?.item?.numero,
      //       adicaoNumero: adicaoNumber,
      //       processo,
      //     });

      // dispatch(
      //   setResponse({
      //     status: response.status,
      //     data: saved,
      //     message: feedbackMessage,
      //   })
      // );

      // // error feedback
      // dispatch(failure());
      // dispatch(
      //   setErrorFeedback({
      //     message,
      //   })
      // );
    },
    [idFatura]
  );

  const handleAlternativeSaveItem = useCallback(() => {
    if (isEmpty(controlComponentProps) || !isFunction(controlComponentProps?.handleAlternativeSave)) {
      throw Error('Error: "handleAlternativeSave" is not a function.');
    }

    const { handleAlternativeSave } = controlComponentProps;

    const itensStepIndex = 6;

    handleAlternativeSave(null, itensStepIndex);
  }, [controlComponentProps]);

  const modes = useMemo(
    () => ({
      isCreate,
      isConsult,
      isSubConsult,
      isSubNone,
      isSubCreate,
      isSubUpdate,
      isFillCommonDataMode,
    }),
    [isCreate, isConsult, isSubConsult, isSubNone, isSubCreate, isSubUpdate, isFillCommonDataMode]
  );

  const mercadoriaFormProps = useMemo(
    () => ({
      faturaValues: parentModel,
      listCatalogo: catalogoProdutoList,
      unidadeMedidaList,
      isFirstFormLoadValues,
      setIsFirstFormLoadValues,
      rootName: 'adicionais',
    }),
    [parentModel, catalogoProdutoList, unidadeMedidaList, isFirstFormLoadValues, setIsFirstFormLoadValues]
  );

  const [onBlurObjectHandlers, mercadoriaChangeListeners] = useMercadoriaListenersGroup({
    modes,
    formProps: mercadoriaFormProps,
    subscribeToFilling,
    isSobrescreveDadosPartnumber,
    getTipoCalculoValue,
  });

  const catalogoProdutoActions = useMemo(
    () => ({
      nveActions,
      nveAtributoActions,
      nveEspecificacaoActions,
    }),
    [nveActions, nveAtributoActions, nveEspecificacaoActions]
  );

  const catalogoProdutoFormProps = useMemo(
    () => ({
      ncmList,
      ncm: itemModel?.ncm,
      especificacao: nveEspecificacao,
    }),
    [ncmList, itemModel, nveEspecificacao]
  );

  const catalogoProdutosAtributosChangeListeners = useCatalogoProdutosAtributosListeners({
    rootName: 'ignorableFields.atributoNve',
    modes,
    actions: catalogoProdutoActions,
    formProps: catalogoProdutoFormProps,
  });

  const internalTabManagerProps = useMemo(
    () => ({
      ...tabManagerProps,
      scrollButtons: 'auto',
      variant: 'scrollable',
      tabs: [
        {
          label: t('com.muralis.qcx.lista'),
          disabled: isSubCreate || isSubUpdate,
        },
        {
          label: t('com.muralis.qcx.mercadoria.label'),
          disabled: !hasItems || isSubNone,
        },
        {
          label: t('com.muralis.qcx.detalhes'),
          disabled: !hasItems || isSubNone,
        },
      ],
      onlyActiveTabEnabled: isSubUpdate,
    }),
    [hasItems, isSubNone, isSubCreate, isSubUpdate]
  );

  const internalFormPaginationControlProps = useMemo(
    () => ({
      ...formPaginationControlProps,
      dualControlButtons: {
        primary: {
          paginationInfoField: {
            label: t('com.muralis.qcx.adicao.label'),
            hiddenValue: isSubCreate || isSubUpdate,
          },
          buttonGroup: {
            first: {
              description: t('com.muralis.qcx.adicao.primeiraAdicao'),
            },
            next: {
              description: t('com.muralis.qcx.adicao.proximaAdicao'),
            },
            previous: {
              description: t('com.muralis.qcx.adicao.adicaoAnterior'),
            },
            last: {
              description: t('com.muralis.qcx.adicao.ultimaAdicao'),
            },
          },
        },
        secondary: {
          paginationInfoField: {
            label: t('com.muralis.qcx.item.labelPlural'),
            hiddenValue: isSubCreate || isSubUpdate,
          },
        },
      },
      getSubListByParent: (parent) => parent?.mercadorias || [],
    }),
    [isSubCreate, isSubUpdate, formPaginationControlProps]
  );

  // Graças ao flex do ações, utilize multiplos de 200 no flex
  // Ou seja, 200 equivale a uma unidade de tamanho
  const tableProps = useMemo(
    () => ({
      title: t('com.muralis.qcx.itensAdicionados'),
      showDuplicar: true,
      columns: [
        {
          field: 'nroAdicao',
          headerName: 'Ad',
          headerAlign: 'center',
          align: 'center',
          flex: 130,
          valueGetter: ({ row }) => row?.metadata?.item?.adicao || '-',
        },
        {
          field: 'nroItem',
          headerName: 'It',
          headerAlign: 'center',
          align: 'center',
          flex: 130,
          valueGetter: ({ row }) => row?.metadata?.item?.numero || '-',
        },
        {
          field: 'partNum',
          headerName: 'Pn',
          headerAlign: 'center',
          align: 'center',
          flex: 220,
          renderCell: ({ row }) => <TableCellWithTooltip value={row?.partnumber || '-'} />,
          valueGetter: ({ row }) => row?.partnumber,
        },
        {
          field: 'descricaoResumida',
          headerName: 'Desc.',
          flex: 260,
          renderCell: ({ row }) => <TableCellWithTooltip value={row?.descricaoResumida} />,
          valueGetter: ({ row }) => row?.descricaoResumida,
        },
        {
          field: 'quebraAuxiliar',
          headerName: 'Quebra',
          width: 120,
          valueGetter: ({ row }) => row?.quebraAuxiliar || '',
          headerAlign: 'center',
          align: 'center',
        },
        {
          field: 'exportador',
          headerName: 'Exp.',
          headerAlign: 'center',
          align: 'center',
          flex: 210,
          renderCell: ({ row }) => (
            <TableCellWithTooltip value={row?.exportador?.pessoaJuridica?.nomeResumido?.split(' ')?.[0] || '-'} />
          ),
          valueGetter: ({ row }) => row?.exportador?.pessoaJuridica?.nomeResumido?.split(' ')?.[0] || '-',
        },
        {
          field: 'fabricante',
          headerName: 'Fab.',
          headerAlign: 'center',
          align: 'center',
          flex: 210,
          renderCell: ({ row }) => (
            <TableCellWithTooltip value={row?.fabricante?.pessoaJuridica?.nomeResumido?.split(' ')[0] || '-'} />
          ),
          valueGetter: ({ row }) => row?.fabricante?.pessoaJuridica?.nomeResumido?.split(' ')[0] || '-',
        },
        {
          field: 'ncm',
          headerName: 'NCM',
          headerAlign: 'center',
          align: 'center',
          flex: 240,
          renderCell: ({ row }) => <TableCellWithTooltip value={row?.ncm?.code || '-'} />,
          valueGetter: ({ row }) => row?.ncm?.code || '-',
        },
        {
          field: 'quantidade',
          headerName: 'Qtde',
          headerAlign: 'center',
          align: 'center',
          flex: 170,
          renderCell: ({ row }) => (
            <TableCellWithTooltip
              value={unnormalizeNumeral(row?.quantidade, formatBrazilianNumericDecimal(5)) || '-'}
            />
          ),
        },
        {
          field: 'valorTotalMoeda',
          headerName: t('com.muralis.qcx.total'),
          headerAlign: 'center',
          align: 'center',
          flex: 200,
          renderCell: ({ row }) => (
            <TableCellWithTooltip
              value={unnormalizeNumeral(row?.valorTotalMoeda, formatBrazilianNumericDecimal(2)) || '-'}
            />
          ),
        },
        {
          field: 'pesoLiquido',
          headerName: 'Peso',
          headerAlign: 'center',
          align: 'center',
          flex: 200,
          renderCell: ({ row }) => (
            <TableCellWithTooltip
              value={unnormalizeNumeral(row?.pesoLiquido, formatBrazilianNumericDecimal(5)) || '-'}
            />
          ),
        },
      ],
    }),
    []
  );

  const tableData = useMemo(() => {
    if (isEmpty(adicoes)) {
      return mercadorias;
    }

    if (isSubConsult) {
      const modifiedAdicoes = adicoes.map((adicao) => ({
        ...adicao,
        mercadorias: adicao?.mercadorias?.map((mercadoria) => ({
          ...mercadoria,
          metadata: {
            parent: {
              id: adicao?.id,
              numero: adicao?.item,
            },
            item: {
              numero: mercadoria?.itemAdicao,
              adicao: adicao?.item,
            },
          },
        })),
      }));

      return modifiedAdicoes;
    }

    const groupByParent = (parent) => (subPrevious, subCurrent) =>
      [
        ...subPrevious,
        {
          ...subCurrent,
          metadata: {
            parent: {
              id: parent?.id,
              numero: parent?.item,
            },
            item: {
              numero: subCurrent?.itemAdicao,
              adicao: parent?.item,
            },
          },
        },
      ];

    const groupByMercadorias = (previous, current) => [
      ...previous,
      ...current?.mercadorias?.reduce(groupByParent(current), []),
    ];

    const modifiedMercadoriasFromAdicoes = adicoes.reduce(groupByMercadorias, []);

    modifiedMercadoriasFromAdicoes.sort((a, b) => {
      if (a?.metadata?.item?.adicao !== b?.metadata?.item?.adicao) {
        return a.metadata.item.adicao - b.metadata.item.adicao;
      }

      return a?.metadata?.item?.numero - b?.metadata?.item?.numero;
    });

    return modifiedMercadoriasFromAdicoes;
  }, [adicoes, mercadorias, isSubConsult]);

  const isDualFormPagination = useMemo(() => !isEmpty(adicoes), [adicoes]);

  const sortableValueGetter = useCallback(
    (data) => (isDualFormPagination ? data?.metadata?.parent?.numero : data?.item),
    [isDualFormPagination]
  );

  const localeTableText = useMemo(
    () => ({
      noRowsLabel: t('com.muralis.qcx.validacao.nenhumItemEncontrado'),
    }),
    []
  );

  const currentTitleSubPage = useMemo(
    () => (isSubCreate ? t('com.muralis.qcx.item.novoItem') : t('com.muralis.qcx.item.dadosItem')),
    [isSubCreate]
  );

  const relacaoFornecedorChangeListeners = useFornecedorListenersGroup();

  const changeListeners = useMemo(
    () => [
      ...mercadoriaChangeListeners,
      ...catalogoProdutosAtributosChangeListeners,
      ...relacaoFornecedorChangeListeners,
    ],
    [mercadoriaChangeListeners, catalogoProdutosAtributosChangeListeners, relacaoFornecedorChangeListeners]
  );

  useEffect(() => {
    const cleanUpOnUnmount = () => {
      handleChangeToSubNone();
    };

    return cleanUpOnUnmount;
  }, []);

  return (
    <>
      <QCXFormStepWizard
        onSubmit={handleSubmitNew}
        controlComponentProps={controlComponentProps}
        onChangeListeners={changeListeners}
        alternativeMode
        {...restProps}
      >
        {({ submitting, submitSucceeded, values: values_, form }) => (
          <>
            <QCXCustomRegistrationTabControlTemplate
              autoControlInitialValues
              autoFormReset
              defaultItem={defaultItem}
              defaultRestartedValues={defaultRestartedValues}
              dualFormControlPagination={isDualFormPagination}
              formPaginationControlProps={internalFormPaginationControlProps}
              handleAlternativeSave={handleAlternativeSaveItem}
              handleCancelCreate={handleCancelCreate}
              handleCancelUpdate={handleInternalCancelUpdate}
              handleChangeModel={handleChangeModel}
              handleChangeToConsult={handleChangeToConsult}
              handleChangeToSubConsult={handleChangeToSubConsult}
              handleChangeToSubNone={handleChangeToSubNone}
              handleChangeToUpdate={handleChangeToUpdate}
              handleClearForm={handleClearForm}
              handleEdit={handleEdit}
              handleLoadingStatus={handleLoadingStatus}
              handleMetadataUpdate={handleMetadataUpdate}
              handleNew={handleNew}
              handleRemoveByForm={handleRemoveByForm}
              handleRemoveByTable={handleRemoveByTable}
              handleResetStatus={handleResetStatus}
              initialValues={computedInitialValues}
              isBackgroundDelete={isBackgroundDelete}
              isConsult={isConsult}
              isCreate={isCreate}
              isIdle={isIdle}
              isLoading={isLoading}
              isPreparingAction={isPreparingAction}
              isSubBackgroundConsult={isSubBackgroundConsult}
              isSubBackgroundCreate={isSubBackgroundCreate}
              isSubBackgroundDelete={isSubBackgroundDelete}
              isSubBackgroundUpdate={isSubBackgroundUpdate}
              isSubConsult={isSubConsult}
              isSubCreate={isSubCreate}
              isSubNone={isSubNone}
              isSubUpdate={isSubUpdate}
              isUpdate={isUpdate}
              localeTableText={localeTableText}
              sortableValueGetter={sortableValueGetter}
              submitSucceeded={submitSucceeded}
              submitting={submitting}
              tableData={tableData}
              tableProps={tableProps}
              tabManagerProps={internalTabManagerProps}
              title={currentTitleSubPage}
              tabChangeCallback={handleTabChange}
            >
              {() => (
                <>
                  <QCXFaturaMercadoriaStandardFormGroup
                    tipo={isTipoDeclaracaoDuimp ? TIPO_DUIMP : TIPO_DI_DUIMP}
                    modes={{
                      isConsult,
                      isSubConsult,
                      isSubNone,
                    }}
                    classes={classes}
                    authInfo={authInfo}
                    isFilling={isFilling}
                    onBlurObjectHandlers={onBlurObjectHandlers}
                    idFatura={idFatura}
                    isDIDUIMP={isDIDUIMP}
                    isTipoDeclaracaoDuimp={isTipoDeclaracaoDuimp}
                  />
                  {/* FIXME: Fake field */}
                  <div className={classes.hiddenFakeFields}>
                    <QCXFaturaAdicionaisFormGroup modes={modes} />
                  </div>

                  {isDIDUIMP ? (
                    <>
                      <QCXFaturaAtributosNcmDinamico modes={modes} codObjetivo={5} />
                    </>
                  ) : (
                    <></>
                  )}

                  {isDIDUIMP ? (
                    <>
                      <Box mt={3} width="100%">
                        <Grid container>
                          <Grid item xs={12}>
                            <QCXFormSubtitle title="LPCO" />
                          </Grid>
                        </Grid>

                        <Grid container spacing={2} style={{ marginTop: '1rem' }}>
                          <Grid item xs={12}>
                            <QCXMercadoriaLPCOBondManager isConsult={modes?.isConsult} />
                          </Grid>
                        </Grid>
                      </Box>

                      <Box mt={3} width="100%">
                        <Grid container>
                          <Grid item xs={12}>
                            <QCXFormSubtitle title="Declarações Vinculadas" />
                          </Grid>
                        </Grid>

                        <Grid container spacing={2} style={{ marginTop: '1rem' }}>
                          <Grid item xs={12}>
                            <QCXMercadoriaDeclaracaoVinculadaBondManager isConsult={modes?.isConsult} />
                          </Grid>
                        </Grid>
                      </Box>
                    </>
                  ) : null}
                </>
              )}
              {() => (
                <>
                  <QCXFaturaAdicionaisFormGroup isDuimp={isDIDUIMP} modes={modes} />
                  {!isSomeTipoDeclaracaoBy([TIPO_DECLARACAO_DUIMP]) && (
                    <>
                      <Grid item className={classes.subtitle} xs={12}>
                        <QCXFormSubtitle title={t('com.muralis.qcx.NVE.NVEatributos')} />
                      </Grid>
                      <QCXFinalAtributoNveBondManager
                        classes={classes}
                        modes={modes}
                        listProps={{
                          rootName: 'atributos',
                        }}
                      />
                    </>
                  )}
                  <>
                    <Grid item xs={12}>
                      <QCXFormSubtitle title="Lotes" />
                    </Grid>

                    <QCXFinalLotesBondManager
                      modes={modes}
                      listProps={{
                        rootName: 'lotes',
                      }}
                      isParentConsult={isConsult}
                    />
                  </>
                </>
              )}
            </QCXCustomRegistrationTabControlTemplate>
            {showRepasseNVEButton && (
              <>
                <Box width="100%" display="flex" justifyContent="flex-end" mt={3}>
                  <QCXButton variant="contained" color="secondary" onClick={() => setIsRepasseNVEDialogOpen(true)}>
                    Repasse de NVE
                  </QCXButton>
                </Box>

                <RepassarAtributosNVEItens
                  handleSubmit={() => handleSubmitLegacy(values_, form)}
                  open={isRepasseNVEDialogOpen}
                  onClose={() => setIsRepasseNVEDialogOpen(false)}
                  itensFatura={mercadorias}
                />
              </>
            )}
          </>
        )}
      </QCXFormStepWizard>
      <QCXConfirmDialog
        key="confirm-dialog-remove-item-adicao"
        id="confirm-dialog-remove-item-adicao"
        open={operationOfRemoveById?.active}
        title={operationOfRemoveById?.title}
        content={operationOfRemoveById?.message}
        renderMiddleMessage={operationOfRemoveById?.renderMiddleMessage}
        endContent={operationOfRemoveById?.endMessage}
        onConfirm={operationOfRemoveById.confirm}
        onClose={operationOfRemoveById?.reset}
      />
    </>
  );
}
