import { MenuItem } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { doGetQCXRequest } from '../../utils/api/domain/domain-api-utils';
import { PAIS_ENDPOINT } from '../../utils/api/domain/domain-endpoint-utils';
import QCXTextField from '../text-field/QCXTextField';

export default function QCXSelectOrgaoLPCOAutocomplete({ value, onChange, ...restProps }) {
  const { t } = useTranslation();
  const [orgaos, setOrgaos] = useState([]);

  const fetchAll = async () => {
    const response = await doGetQCXRequest(['mercadoria', 'orgao-lpco']);

    const { data } = response;

    setOrgaos(data);
  };

  useEffect(() => {
    fetchAll();
  }, []);

  return (
    <QCXTextField
      style={{ width: '100%' }}
      label="Orgão LPCO"
      key="select-orgao-lpco"
      id="select-orgao-lpco"
      select
      value={value}
      onChange={onChange}
      {...restProps}
    >
      <MenuItem default selected value="">
        {t('com.muralis.qcx.selecionePlaceholder')}
      </MenuItem>
      {orgaos.map(({ id, code, description, active }) => (
        <MenuItem key={`select-item-orgao-lpco-${code.toLowerCase()}`} value={id} disabled={!active}>
          {`${code} - ${description}`}
        </MenuItem>
      ))}
    </QCXTextField>
  );
}
