import {
  doGetQCXRequest,
} from '../../utils/api/domain/domain-api-utils';

export async function fetchByNcm(ncmCode) {
  return doGetQCXRequest([
    "atributoncm",
    "fundamento-legal-ipi",
    ncmCode
  ]);
}


export default {
  fetchByNcm,
}