import { Box, Grid } from '@material-ui/core';
import { useCallback, useMemo } from 'react';
import { useForm } from 'react-final-form';
import { v4 as uuid } from 'uuid';
import QCXFinalBondManager from '../../shared-components/final-gerenciador-relacionamento/QCXFinalBondManager';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXSelectMercadoriaTipoDeclaracaoVinculada from '../../shared-components/select-mercadoria-tipo-declaracao-vinculada/QCXSelectMercadoriaTipoDeclaracaoVinculada';
import { isStrictEquals } from '../../utils/general/general-utils';

export default function QCXMercadoriaDeclaracaoVinculadaBondManager({
  isConsult = false,
  listRootName = 'listaMercadoriaDeclaracaoVinculada',
}) {
  const form = useForm();

  const handleAdd = useCallback((currentVinculo, currentVinculos, handleSuccessAddVinculo) => {
    const novoVinculo = {
      id: uuid(),
      tipoDeclaracao: currentVinculo.tipoDeclaracao,
      numeroDeclaracao: currentVinculo.numeroDeclaracao,
    };

    const updatedVinculos = [...currentVinculos, novoVinculo];

    handleSuccessAddVinculo(updatedVinculos);
  }, []);

  const handleUpdate = useCallback((currentVinculo, currentVinculos, handleSuccessUpdateVinculo) => {
    const updatedVinculos =
      currentVinculos?.map((existingVinculo) =>
        isStrictEquals(existingVinculo?.id, currentVinculo?.id) ? currentVinculo : existingVinculo
      ) || currentVinculos;

    handleSuccessUpdateVinculo(updatedVinculos);
  }, []);

  const handleAlreadyExists = useCallback(
    (currentVinculo, currentVinculosList) =>
      currentVinculosList.some(
        (vinculo) =>
          isStrictEquals(vinculo?.orgao, currentVinculo?.orgao) &&
          isStrictEquals(vinculo?.nomeLpco, currentVinculo?.nomeLpco)
      ),
    []
  );

  const listProps = useMemo(
    () => ({
      name: listRootName,
      columns: [
        {
          field: 'tipoDeclaracao',
          headerName: 'Tipo',
          flex: 200,
          valueGetter: ({ row }) => row?.tipoDeclaracao ?? '-',
        },
        {
          field: 'numeroDeclaracao',
          headerName: 'Número',
          flex: 200,
          valueGetter: ({ row }) => row?.numeroDeclaracao ?? '-',
        },
      ],
    }),
    []
  );

  const formProps = useMemo(
    () => ({
      rootName: 'ignorableFields.mercadoriaDeclaracaoVinculadaBond',
      fields: [
        {
          name: 'tipoDeclaracao',
          label: 'Tipo',
        },
        {
          name: 'numeroDeclaracao',
          label: 'Número',
        },
      ],
    }),
    []
  );

  const handleTipoChange = (e) =>
    form.change('ignorableFields.mercadoriaDeclaracaoVinculadaBond.tipoDeclaracao', e.target.value);

  return (
    <Box style={{ pointerEvents: isConsult ? 'none' : '' }}>
      <QCXFinalBondManager
        isParentConsult={isConsult}
        listProps={listProps}
        formProps={formProps}
        controlButtonsGridProps={{
          add: {
            xs: 12,
            sm: 2,
            md: 2,
          },
          update: {
            xs: 6,
            sm: 6,
            md: 1,
          },
          clear: {
            xs: 6,
            sm: 6,
            md: 1,
          },
        }}
        handleAdd={handleAdd}
        handleUpdate={handleUpdate}
        handleAlreadyExists={handleAlreadyExists}
      >
        <>
          <Grid item sm={4}>
            <QCXSelectMercadoriaTipoDeclaracaoVinculada
              id="mercadoria-declaracao-tipo"
              key="mercadoria-declaracao-tipo"
              name="ignorableFields.mercadoriaDeclaracaoVinculadaBond.tipoDeclaracao"
              label="Tipo"
              disabled={isConsult}
              onChange={handleTipoChange}
            />
          </Grid>
          <Grid item sm={4}>
            <QCXFinalTextField
              id="mercadoria-declaracao-numero"
              key="mercadoria-declaracao-numero"
              name="ignorableFields.mercadoriaDeclaracaoVinculadaBond.numeroDeclaracao"
              label="Número Declaração"
              disabled={isConsult}
            />
          </Grid>
        </>
      </QCXFinalBondManager>
    </Box>
  );
}
