import { Grid } from '@material-ui/core';
import { omit } from 'lodash';
import { useCallback, useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectConditionalSteps,
  selectSubMode,
} from '../../features/declaracao-importacao/declaracaoImportacaoSelectors';
import {
  changeToSubBackgroundConsultMode,
  changeToSubConsultMode,
  changeToSubCreateMode,
  changeToSubUpdateMode,
  loading,
  resetStatus,
  resetSubBackgroundMode,
  resetSubMode,
} from '../../features/declaracao-importacao/declaracaoImportacaoSlice';
import { selectTiposDeclaracao } from '../../features/tipo-declaracao/tipoDeclaracaoSlice';
import QCXFinalFormWizard from '../../shared-components/final-form-wizard/QCXFinalFormWizard';
import {
  TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PE_PRODUTO_ESTRANGEIRO,
  TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PI_PRODUTO_INDUSTRIALIZADO,
  TIPO_DECLARACAO_INTERNACAO_DE_ALC_AREA_DE_LIVRE_COMERCIO,
  TIPO_DECLARACAO_SAIDA_DE_ENTREPOSTO_INDUSTRIAL,
  configureGeneralTipoDeclaracaoMatcherWith,
} from '../../shared-components/select-tipo-declaracao/tipoDeclaracaoUtils';
import DeclaracaoImportacaoActionsMenu from '../../ts/pages/importacao/declaracaoImportacao/components/declaracaoImportacaoActionsMenu.tsx';
import { isNoneSubMode, isSubConsultMode, isSubCreateMode, isSubUpdateMode } from '../../utils/store/store-utils';
import QCXDeclaracaoImportacaoAdicoesForm from './adicoesForm/QCXDeclaracaoImportacaoAdicoesForm';
import QCXDeclaracaoImportacaoAuxiliarForm from './QCXDeclaracaoImportacaoAuxiliarForm';
import QCXDeclaracaoImportacaoBasicasForm from './QCXDeclaracaoImportacaoBasicasForm';
import QCXDeclaracaoImportacaoCargaForm from './QCXDeclaracaoImportacaoCargaForm';
import QCXDeclaracaoImportacaoFornecedorForm from './QCXDeclaracaoImportacaoFornecedorForm';
import QCXDeclaracaoImportacaoItensForm from './QCXDeclaracaoImportacaoItensForm';
import QCXDeclaracaoImportacaoPagamentosForm from './QCXDeclaracaoImportacaoPagamentosForm';
import QCXDeclaracaoImportacaoTotaisForm from './QCXDelaracaoImportacaoTotaisForm/QCXDeclaracaoImportacaoTotaisForm';
import QCXDeclaracaoImportacaoTransporteForm from './QCXDeclaracaoImportacaoTransporteForm';
import QCXDeclaracaoImportacaoDocumentosForm from './QCXDeclaracaoImportacaoDocumentosForm';

export default function QCXDeclaracaoImportacaoWizardFinalForm({
  isIdle,
  isLoading,
  isFailure,
  isCreate,
  isConsult,
  isUpdate,
  isLocked,
  isBackgroundCreate,
  isBackgroundDelete,
  isSubBackgroundCreate,
  isSubBackgroundConsult,
  isSubBackgroundUpdate,
  isSubBackgroundDelete,
  isPreparingAction,
  handleSubmit,
  handleCustomSaveBy,
  handleAlternativeSave,
  model,
  handleChangeModel,
  handleChangeToCreate,
  handleChangeToConsult,
  handleChangeToUpdate,
  handleCancelUpdate,
  handleChangeToBackgroundCreate,
  handleChangeToBackgroundDelete,
  handleResetBackgroundMode,
  authInfo = {},
  requiredRoles = [],
  children,
  openAskRepassarMoedasPopUp,
  openAskLimparAcrescimosDeducoes,
  ...restProps
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const subMode = useSelector(selectSubMode);
  const tiposDeclaracao = useSelector(selectTiposDeclaracao);
  const conditionalSteps = useSelector(selectConditionalSteps);

  const [isStepFornecedorConditional, setIsStepFornecedorConditional] = useState(false);

  const checkIfIsAlternativeSaveByStep = (currentStep) => [6, 7, 8].includes(currentStep);

  const tipoDeclaracaoDuimp = useMemo(
    () => (tiposDeclaracao ? [...tiposDeclaracao].find((tipo) => tipo?.description === 'DUIMP') : null),
    [tiposDeclaracao]
  );
  const isDIDUIMP = useMemo(
    () => model?.atributosAdicionais?.tipoDeclaracao?.id === tipoDeclaracaoDuimp?.id,
    [model, tipoDeclaracaoDuimp]
  );

  const isSubNone = useMemo(() => isNoneSubMode(subMode), [subMode]);

  const isSubCreate = useMemo(() => isSubCreateMode(subMode), [subMode]);

  const isSubConsult = useMemo(() => isSubConsultMode(subMode), [subMode]);

  const isSubUpdate = useMemo(() => isSubUpdateMode(subMode), [subMode]);

  const handleLoadingStatus = useCallback(() => dispatch(loading()), []);

  const handleResetStatus = useCallback(() => dispatch(resetStatus()), []);

  const handleChangeToSubCreate = useCallback(() => {
    dispatch(changeToSubCreateMode());
  }, []);

  const handleChangeToSubConsult = useCallback(() => {
    dispatch(resetSubBackgroundMode());
    dispatch(changeToSubConsultMode());
  }, []);

  const handleChangeToSubUpdate = useCallback(() => {
    dispatch(changeToSubBackgroundConsultMode());
    dispatch(changeToSubUpdateMode());
  }, []);

  const handleChangeToSubNone = () => {
    dispatch(resetSubMode());
  };

  const tipoDeclaracao = useCallback((values) => values?.atributosAdicionais?.tipoDeclaracao?.id, []);

  const isTipoDeclaracaoticoConditionalBy = useCallback(
    (values, codesToCompare) =>
      configureGeneralTipoDeclaracaoMatcherWith(tiposDeclaracao)(tipoDeclaracao(values), codesToCompare),
    [tipoDeclaracao, tiposDeclaracao]
  );

  const conditionalTiposDeclaracao = useMemo(
    () => [
      TIPO_DECLARACAO_SAIDA_DE_ENTREPOSTO_INDUSTRIAL,
      TIPO_DECLARACAO_INTERNACAO_DE_ALC_AREA_DE_LIVRE_COMERCIO,
      TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PE_PRODUTO_ESTRANGEIRO,
      TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PI_PRODUTO_INDUSTRIALIZADO,
    ],
    []
  );

  const wizardSteps = useMemo(() => {
    const stepsLabels = [
      t('com.muralis.qcx.basicas'),
      t('com.muralis.qcx.transporte.label'),
      t('com.muralis.qcx.carga.label'),
      t('com.muralis.qcx.documentos.label'),
      t('com.muralis.qcx.totais'),
      t('com.muralis.qcx.pagamentos'),
      t('com.muralis.qcx.fornecedor.label'),
      t('com.muralis.qcx.item.labelPlural'),
      t('com.muralis.qcx.auxiliar'),
      t('com.muralis.qcx.adicoes'),
    ];

    const stepsActive = stepsLabels.filter((step) => {
      if (!isDIDUIMP && step === t('com.muralis.qcx.documentos.label')) return false;
      return true;
    });

    return stepsActive;
  }, [isDIDUIMP]);

  const optionalSteps = useMemo(() => [t('com.muralis.qcx.fornecedor.label')], []);

  const ignoreRulesForSpecialSteps = useMemo(
    () => [t('com.muralis.qcx.item.labelPlural'), t('com.muralis.qcx.adicoes')],
    []
  );

  const handleTipoDeclaracaoChange = useCallback(
    (value) => setIsStepFornecedorConditional(value),
    [setIsStepFornecedorConditional]
  );

  const wizardConditionalSteps = useMemo(
    () =>
      isStepFornecedorConditional || (isTipoDeclaracaoticoConditionalBy(model, conditionalTiposDeclaracao) && isCreate)
        ? conditionalSteps
        : [],
    [
      model,
      conditionalSteps,
      isStepFornecedorConditional,
      conditionalTiposDeclaracao,
      isTipoDeclaracaoticoConditionalBy,
    ]
  );

  const handleFormSubmit = (values, step, next) => {
    const dataEmbarque = values?.atributosAdicionais?.transporte?.dataEmbarque;
    const transformedValues = {
      ...values,
      atributosAdicionais: {
        ...values.atributosAdicionais,
        transporte: {
          ...values.atributosAdicionais.transporte,
          dataEmbarque: dataEmbarque ? dataEmbarque.format('YYYY-MM-DDTHH:mm:ss') : null,
        },
      },
    };
    handleSubmit(transformedValues, step, next);
  };

  return (
    <>
      <Grid container justify="flex-end" alignContent="center">
        <DeclaracaoImportacaoActionsMenu
          id={model.id}
          calculada={model.calculada}
          atributosAdicionais={model.atributosAdicionais}
          active={model.active}
          authInfo={authInfo}
        />
      </Grid>
      <QCXFinalFormWizard
        steps={wizardSteps}
        optionalSteps={optionalSteps}
        conditionalSteps={wizardConditionalSteps}
        ignoreRulesForSpecialSteps={ignoreRulesForSpecialSteps}
        initialValues={model}
        handleChangeValues={handleChangeModel}
        isCreate={isCreate}
        isConsult={isConsult}
        isUpdate={isUpdate}
        isBackgroundCreate={isBackgroundCreate}
        isSubNone={isSubNone}
        isSubCreate={isSubCreate}
        isSubConsult={isSubConsult}
        isSubUpdate={isSubUpdate}
        handleSubmit={handleFormSubmit}
        checkIfIsAlternativeSaveByStep={checkIfIsAlternativeSaveByStep}
        handleAlternativeSave={handleAlternativeSave}
        handleChangeToCreate={handleChangeToCreate}
        handleChangeToBackgroundCreate={handleChangeToBackgroundCreate}
        handleChangeToConsult={handleChangeToConsult}
        handleChangeToUpdate={handleChangeToUpdate}
        handleCancelUpdate={handleCancelUpdate}
        handleResetBackgroundMode={handleResetBackgroundMode}
        {...restProps}
      >
        {(formProps) => (
          <QCXDeclaracaoImportacaoBasicasForm
            key="ficha-basicas-declaracao-importacao"
            isConsult={isConsult}
            authInfo={authInfo}
            requiredRoles={requiredRoles}
            isLocked={isLocked}
            handleConditionalSteps={handleTipoDeclaracaoChange}
            isDuimp={isDIDUIMP}
            {...formProps}
          />
        )}
        {(formProps) => (
          <QCXDeclaracaoImportacaoTransporteForm
            key="ficha-transportador-declaracao-importacao"
            isConsult={isConsult}
            authInfo={authInfo}
            requiredRoles={requiredRoles}
            isLocked={isLocked}
            isDuimp={isDIDUIMP}
            {...formProps}
          />
        )}
        {(formProps) => (
          <QCXDeclaracaoImportacaoCargaForm
            key="ficha-carga-declaracao-importacao"
            isConsult={isConsult}
            isCreate={isCreate}
            authInfo={authInfo}
            requiredRoles={requiredRoles}
            isLocked={isLocked}
            isDuimp={isDIDUIMP}
            {...formProps}
          />
        )}

        {isDIDUIMP
          ? (formProps) => (
              <QCXDeclaracaoImportacaoDocumentosForm
                key="ficha-documentos-declaracao-importacao"
                isConsult={isConsult}
                isCreate={isCreate}
                authInfo={authInfo}
                requiredRoles={requiredRoles}
                isLocked={isLocked}
                {...formProps}
              />
            )
          : null}

        {(formProps) => (
          <QCXDeclaracaoImportacaoTotaisForm
            key="ficha-totais-declaracao-importacao"
            isConsult={isConsult}
            authInfo={authInfo}
            requiredRoles={requiredRoles}
            parentModel={model}
            openAskRepassarMoedasPopUp={openAskRepassarMoedasPopUp}
            openAskLimparAcrescimosDeducoes={openAskLimparAcrescimosDeducoes}
            isDuimp={isDIDUIMP}
            {...formProps}
          />
        )}
        {(formProps) => (
          <QCXDeclaracaoImportacaoPagamentosForm
            key="ficha-pagamentos-declaracao-importacao"
            isConsult={isConsult}
            authInfo={authInfo}
            requiredRoles={requiredRoles}
            isLocked={isLocked}
            isDuimp={isDIDUIMP}
            {...formProps}
          />
        )}
        {(formProps) => (
          <QCXDeclaracaoImportacaoFornecedorForm
            key="ficha-fornecedor-declaracao-importacao"
            isConsult={isConsult}
            authInfo={authInfo}
            requiredRoles={requiredRoles}
            isDuimp={isDIDUIMP}
            {...formProps}
          />
        )}
        {({ controlComponentProps, ...restFormProps }) => (
          <QCXDeclaracaoImportacaoItensForm
            authInfo={authInfo}
            controlComponentProps={controlComponentProps}
            handleCancelUpdate={handleCancelUpdate}
            handleChangeToBackgroundDelete={handleChangeToBackgroundDelete}
            handleChangeToConsult={handleChangeToConsult}
            handleChangeToSubConsult={handleChangeToSubConsult}
            handleChangeToSubCreate={handleChangeToSubCreate}
            handleChangeToSubNone={handleChangeToSubNone}
            handleChangeToSubUpdate={handleChangeToSubUpdate}
            handleChangeToUpdate={handleChangeToUpdate}
            handleCustomSaveBy={handleCustomSaveBy}
            handleLoadingStatus={handleLoadingStatus}
            handleResetStatus={handleResetStatus}
            isBackgroundDelete={isBackgroundDelete}
            isConsult={isConsult}
            isCreate={isCreate}
            isFailure={isFailure}
            isIdle={isIdle}
            isLoading={isLoading}
            isLocked={isLocked}
            isPreparingAction={isPreparingAction}
            isSubBackgroundConsult={isSubBackgroundConsult}
            isSubBackgroundCreate={isSubBackgroundCreate}
            isSubBackgroundDelete={isSubBackgroundDelete}
            isSubBackgroundUpdate={isSubBackgroundUpdate}
            isSubConsult={isSubConsult}
            isSubCreate={isSubCreate}
            isSubNone={isSubNone}
            isSubUpdate={isSubUpdate}
            isUpdate={isUpdate}
            key="ficha-itens-declaracao-importacao"
            parentModel={model}
            requiredRoles={requiredRoles}
            isDuimp={isDIDUIMP}
            {...omit(restFormProps, ['initialValues', 'handleSubmit'])}
          />
        )}
        {({ controlComponentProps, ...restFormProps }) => (
          <QCXDeclaracaoImportacaoAuxiliarForm
            key="ficha-auxiliar-declaracao-importacao"
            isLoading={isLoading}
            isCreate={isCreate}
            isConsult={isConsult}
            isUpdate={isUpdate}
            parentModel={model}
            handleCustomSaveBy={handleCustomSaveBy}
            handleChangeToConsult={handleChangeToConsult}
            handleChangeToUpdate={handleChangeToUpdate}
            handleCancelUpdate={handleCancelUpdate}
            controlComponentProps={controlComponentProps}
            authInfo={authInfo}
            requiredRoles={requiredRoles}
            isDuimp={isDIDUIMP}
            {...omit(restFormProps, ['initialValues', 'handleSubmit'])}
          />
        )}
        {({ controlComponentProps, ...restFormProps }) => (
          <QCXDeclaracaoImportacaoAdicoesForm
            key="ficha-adicioes-declaracao-importacao"
            isIdle={isIdle}
            isLoading={isLoading}
            isFailure={isFailure}
            isPreparingAction={isPreparingAction}
            isCreate={isCreate}
            isConsult={isConsult}
            isUpdate={isUpdate}
            isSubNone={isSubNone}
            isSubCreate={isSubCreate}
            isSubConsult={isSubConsult}
            isSubUpdate={isSubUpdate}
            isBackgroundDelete={isBackgroundDelete}
            isLocked={isLocked}
            parentModel={model}
            handleCustomSaveBy={handleCustomSaveBy}
            handleChangeToSubNone={handleChangeToSubNone}
            handleChangeToSubCreate={handleChangeToSubCreate}
            handleChangeToSubUpdate={handleChangeToSubUpdate}
            handleChangeToSubConsult={handleChangeToSubConsult}
            handleChangeToConsult={handleChangeToConsult}
            handleChangeToUpdate={handleChangeToUpdate}
            handleCancelUpdate={handleCancelUpdate}
            handleChangeToBackgroundDelete={handleChangeToBackgroundDelete}
            handleLoadingStatus={handleLoadingStatus}
            handleResetStatus={handleResetStatus}
            controlComponentProps={controlComponentProps}
            authInfo={authInfo}
            requiredRoles={requiredRoles}
            isDuimp={isDIDUIMP}
            {...omit(restFormProps, ['initialValues', 'handleSubmit'])}
          />
        )}
      </QCXFinalFormWizard>
      {children}
    </>
  );
}
