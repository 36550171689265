import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, makeStyles } from '@material-ui/core';

import {
  selectRegimesTributacaoIpi,
  fetchByNcmAsync,
} from '../../features/regime-tributacao-fundamento-legal-ipi/regimeTributacaoIpiSlice';

import {
  selectRegimesTributacao,
} from '../../features/regime-tributacao/regimeTributacaoSlice';

import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';
import QCXRenderConditionalComponent from '../../components/catalogo-produtos/QCXRenderConditionalComponent';
import QCXFormSubtitle from '../../components/form-title/QCXFormSubtitle';

const useStyles = makeStyles((theme) => ({
  gridFields: {
    paddingBottom: '16px',
  },
  subtitle: {
    paddingTop: '16px !important',
  },
  destaqueNcmFieldLeft: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: '16px',
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: '16px',
    },
  },
  // FIXME: Fake field
  hiddenFakeFields: {
    width: '0px',
    height: '0px',
    visibility: 'hidden',
    zIndex: -9999,
    position: 'absolute',
  },
}));


export default function QCXSelectFundamentoLegalRegimeTributacaoIpiAutocomplete({
  label,
  name,
  initialValues,
  isConsult,
  isDuimp=false,
  ...restProps
}) {
  const classes = useStyles();
  const regimesTributacao = useSelector(selectRegimesTributacao);
  const fundamentosRegime = useSelector(selectRegimesTributacaoIpi);

  const [defaultValue, setDefaultValue] = useState('');
  const [atributos, setAtributos] = useState([]);
  const [list, setList] = useState([]);
  const [regimeSelecionado, setRegimeSelecionado] = useState();

  useEffect(() => {
  
    if (initialValues?.ipi?.regimeTributacao?.id && regimesTributacao && _.isArray(regimesTributacao)) {
      const regimeResult = regimesTributacao.find(regimeAtual => regimeAtual.id === initialValues?.ipi?.regimeTributacao?.id)
      
      if (regimeResult && regimeResult.code) {
        setRegimeSelecionado(regimeResult.code)
      }
    }
  }, [regimesTributacao, fundamentosRegime, initialValues]);


  useEffect(() => {
    if (!regimeSelecionado && !_.isArray(fundamentosRegime)) return ;
    const fundamentosRegimeFiltered = fundamentosRegime.filter(fundamentoAtual => fundamentoAtual.codigoRegime === regimeSelecionado);
    const listaFundamentosParsed = []
    if (fundamentosRegimeFiltered && _.isArray(fundamentosRegimeFiltered) && fundamentosRegimeFiltered.length) {
      if (fundamentosRegimeFiltered[0]?.fundamentosTributarios) {
        fundamentosRegimeFiltered[0]?.fundamentosTributarios.forEach(element => {
          listaFundamentosParsed.push({
            id: element.id,
            code: element.codigoFundamento,
            description: element.nomeFundamento,
            listaAtributosMetadados: element.listaAtributosMetadados,
          })
        });
      }
    }

    setList(listaFundamentosParsed);

    if (initialValues?.ipi?.fundamentoLegalDuimp?.id) {
      setDefaultValue({
        id: initialValues?.ipi?.fundamentoLegalDuimp?.id,
      })
    }
  }, [regimeSelecionado, fundamentosRegime]);

  const filterOptions = createFilterOptions({
    stringify: (item) => `${item?.code} - ${item?.description}`,
  });

  return (
    <>
      <QCXAutocompleteSelect
        label={label}
        id={name}
        name={name}
        options={list}
        filterOptions={filterOptions}
        defaultValue={defaultValue}
        getOptionLabel={(item) => (
          item?.code && item?.description
            ? `${item?.code} - ${item?.description}`
            : ''
        )}
        {...restProps}
      />
      

    </>

  );
}
